import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Login, LoginHandler, LogoutHandler } from "./containers/login";
import AuthenticatedRoute from "./components/inner-components/AuthenticatedRoute";
import { StatstidendePrivileges } from "./services/ClaimService";
import { Error, GlobalErrorNotFound } from "./components/inner-components/error";
const Layout = React.lazy(() => import("./containers/layout/Layout"));
const Home = React.lazy(() => import("./containers/frontpage/Home"));
const AboutContainer = React.lazy(() => import("./containers/about/AboutContainer"));
const Contact = React.lazy(() => import("./containers/contact/Contact"));
const PublicationsContainer = React.lazy(() => import("./containers/publications/PublicationsContainer"));
const Personaldataprocessing = React.lazy(() => import("./containers/personaldataprocessing/Personaldataprocessing"));
const FaqContainer = React.lazy(() => import("./containers/faq/FaqContainer"));
const EmailValidation = React.lazy(() => import("./containers/mypage/emailvalidation/EmailValidation"));
const MessageDetails = React.lazy(() => import("./containers/messagedetails/MessageDetails"));
const MyUserProfile = React.lazy(() => import("./containers/mypage/MyUserProfile"));
const EditUserContainer = React.lazy(() => import("./containers/administration/userprofile/EditUserContainer"));
const UserProfileListContainer = React.lazy(() => import("./containers/administration/userprofile/UserProfileListContainer"));
const UserDisabled = React.lazy(() => import("./containers/userdisabled/UserDisabled"));
const Unsubscribe = React.lazy(() => import("./containers/unsubscribe/Unsubscribe"));
const Subscribe = React.lazy(() => import("./containers/subscribe/Subscribe"));
const SubscribeSearchQuery = React.lazy(() => import("./containers/subscribe/SubscribeSearchQuery"));
const EditMessageContainer = React.lazy(() => import("./containers/message/EditMessageContainer"));
const NewsListContainer = React.lazy(() => import("./containers/news/NewsListContainer"));
const ProductionCalendarContainer = React.lazy(() => import("./containers/editorial/productioncalendar/ProductionCalendarContainer"));
const EditSavedMessageContainer = React.lazy(() => import("./containers/editmessage/EditSavedMessageContainer"));
const MessageReceipt = React.lazy(() => import("./containers/message/MessageReceipt"));
const UnsubscribeSearchQuery = React.lazy(() => import("./containers/unsubscribe/UnsubscribeSearchQuery"));
const GuideContainer = React.lazy(() => import("./containers/guide/GuideContainer"));
const ServiceClientListContainer = React.lazy(() => import("./containers/administration/serviceclients/ServiceClientListContainer"));
const JobSummaryContainer = React.lazy(() => import("./containers/jobsummary/JobSummaryContainer"));
const NewOrEditServiceClient = React.lazy(() => import("./containers/administration/serviceclients/NewOrEditServiceClient"));
const AdminMessageTypes = React.lazy(() => import("./containers/administration/messagetypes/AdminMessageTypes"));
const ConsoleApp = React.lazy(() => import("./containers/signing/ConsoleApp"));
const PdfValidation = React.lazy(() => import("./containers/signing/PdfValidation"));
const PdfCertificates = React.lazy(() => import("./containers/signing/PdfCertificates"));
const MessagesContainer = React.lazy(() => import("./containers/messages/MessagesContainer"));
const EditTeam = React.lazy(() => import("./containers/administration/teams/EditTeam"));
const TeamsListContainer = React.lazy(() => import("./containers/administration/teams/TeamsListContainer"));
const LowLoa = React.lazy(() => import("./containers/login/LowLoa"));
const MessageLogContainer = React.lazy(() => import("./containers/administration/messageLog/MessageLogContainer"));
const ReconfirmSubscriptions = React.lazy(() => import("./containers/ReconfirmSubscriptions/ReconfirmSubscriptions"));
import { Suspense } from "react";

export const routes = (
  <Suspense fallback={<div></div>}>
    <Layout>
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route path="/about" component={AboutContainer} />
        <Route path="/contact" component={Contact} />
        <Route path="/publications" component={PublicationsContainer} />
        <Route path="/personaldataprocessing" component={Personaldataprocessing} />
        <Route path="/faq" component={FaqContainer} />
        <Route path="/login" component={Login} />
        <Route path="/loginhandler/lowloa" component={LowLoa} />
        <Route path="/loginhandler" component={LoginHandler} />
        <Route path="/logouthandler" component={LogoutHandler} />
        <Route path="/userdisabled" component={UserDisabled} />
        <Route path="/unsubscribe" component={Unsubscribe} />
        <Route path="/subscribe" component={Subscribe} />
        <Route path="/confirmsubscription" render={props => <ReconfirmSubscriptions {...props} isQuery={false} />} />
        <Route path="/signing/list" component={PdfCertificates} />
        <Route path="/signing/validate" component={PdfValidation} />
        <Route path="/signing/app" component={ConsoleApp} />
        <Route path="/offentlig/vejledninger" component={GuideContainer} />
        <AuthenticatedRoute
          path="/messages/:messageNumber/edit"
          requiredPrivilege={StatstidendePrivileges.EditMessages}
          component={EditSavedMessageContainer}
        />
        <Route path="/messages" exact={true} component={MessagesContainer} key="search" />
        <Route path="/messages/parameters" exact={true} component={MessagesContainer} key="search-params" />
        <Route path="/messages/subscribe" component={SubscribeSearchQuery} />
        <Route path="/messages/unsubscribe" component={UnsubscribeSearchQuery} />
        <Route path="/messages/confirmSubscription" render={props => <ReconfirmSubscriptions {...props} isQuery={true} />} />
        <AuthenticatedRoute exact={true} path="/messages/add" requiredPrivilege={StatstidendePrivileges.AddMessage} component={EditMessageContainer} />
        <AuthenticatedRoute
          exact={true}
          path="/messages/receipt/:messageNumber"
          requiredPrivilege={StatstidendePrivileges.AddMessage}
          component={MessageReceipt}
        />
        <Route path="/messages/:messageNumber" component={MessageDetails} />
        <Route path="/error" exact={true} component={Error} />
        <Route path="/error/:correlationId" component={Error} />
        <Route path="/mypage/emailvalidation" component={EmailValidation} />
        <AuthenticatedRoute
          exact={true}
          path="/administration/users"
          requiredPrivilege={StatstidendePrivileges.AdminUsers}
          component={UserProfileListContainer}
        />
        <AuthenticatedRoute
          exact={true}
          path="/administration/serviceclients"
          requiredPrivilege={StatstidendePrivileges.AdminServiceClients}
          component={ServiceClientListContainer}
        />
        <AuthenticatedRoute
          path="/administration/serviceclients/:clientId"
          requiredPrivilege={StatstidendePrivileges.AdminServiceClients}
          component={NewOrEditServiceClient}
        />
        <AuthenticatedRoute
          path="/administration/serviceclients/add"
          requiredPrivilege={StatstidendePrivileges.AdminServiceClients}
          component={NewOrEditServiceClient}
        />
        <AuthenticatedRoute path="/administration/messagetypes" requiredPrivilege={StatstidendePrivileges.AdminMessageType} component={AdminMessageTypes} />
        <AuthenticatedRoute path="/administration/messagelog" requiredPrivilege={StatstidendePrivileges.AdminMessageLog} component={MessageLogContainer} />
        <AuthenticatedRoute path="/administration/users/:userId" requiredPrivilege={StatstidendePrivileges.AdminUsers} component={EditUserContainer} />
        <AuthenticatedRoute path="/administration/news" requiredPrivilege={StatstidendePrivileges.AdminNews} component={NewsListContainer} />
        <AuthenticatedRoute path="/administration/invoices" component={PublicationsContainer} />
        <AuthenticatedRoute path="/administration/jobsummary" requiredPrivilege={StatstidendePrivileges.AdminJobSummary} component={JobSummaryContainer} />
        <AuthenticatedRoute path="/mypage/profile" exact={true} component={MyUserProfile} requiredPrivilege={StatstidendePrivileges.EditProfile} />
        <AuthenticatedRoute
          path="/editorial/calendar"
          exact={true}
          component={ProductionCalendarContainer}
          requiredPrivilege={StatstidendePrivileges.AccessProductionCalendar}
        />
        <AuthenticatedRoute path="/administration/teams/:teamId" component={EditTeam} requiredPrivilege={StatstidendePrivileges.AdminOwnTeams} />
        <AuthenticatedRoute path="/administration/teams/add" component={EditTeam} requiredPrivilege={StatstidendePrivileges.AdminTeams} />
        <AuthenticatedRoute path="/administration/teams" exact={true} component={TeamsListContainer} requiredPrivilege={StatstidendePrivileges.AdminOwnTeams} />
        <Route path="*" component={GlobalErrorNotFound} />
      </Switch>
    </Layout>
  </Suspense>
);
