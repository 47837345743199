import * as React from "react";
import { Link } from "react-router-dom";
import { AlertComponent, IGlobalErrorProps } from "./AlertComponent";
import Helmet from "react-helmet";

const GlobalErrorNotFound: React.FC<IGlobalErrorProps> = (props: IGlobalErrorProps) => {
  document.title = "Statstidende | Siden blev ikke fundet";

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <AlertComponent header="Siden blev ikke fundet" correlationId={props.correlationId} isWarning={true}>
        <p>Vi kan ikke finde siden, som du forsøger at besøge. Måske er siden blevet flyttet eller også er webadressen forkert.</p>
        <p>Hjælp til at komme videre:</p>
        <ul>
          <li>Brug søgefunktionen (øverst på siden).</li>
          <li>
            Gå til <Link to="/">forsiden</Link>.
          </li>
        </ul>
      </AlertComponent>{" "}
    </>
  );
};

export default GlobalErrorNotFound;
