import { apiGET } from "./../api";

export interface IConfig {
  numberOfNewsToShowOnFrontpage: number;
  loginSiteUrl: string;
  cityNameProvider: string;
}

export function GetConfig() {
  return apiGET<IConfig>("api/config");
}
