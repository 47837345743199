import { createAction } from "../actionHelper";
import { apiGET } from "../../api";

export enum TextActionEnum {
  REQUEST_ALL = "REQUEST_ALL",
  RECEIVE_ALL = "RECEIVE_ALL",
}

export interface ITextActions {
  getAllTexts(): Promise<void>;
}

export interface ITextState {
  aboutDanish: string;
  aboutPrices: string;
  personaldataprocessing: string;
  externalSiteLinks: Array<{ key: string; value: string }>;
  contact: string;
  faq: string;
  migratedMessageWarning: string;
}

const receiveAll = (texts: ITextState) => createAction(TextActionEnum.RECEIVE_ALL, texts);

const getAllTexts = () => async (dispatch: any, getState: any) => {
  const texts = getState().texts;

  if (texts !== null) {
    return;
  }

  dispatch(receiveAll(await apiGET<ITextState>("api/text")));
};

export const TextActions = {
  getAllTexts,
};

export default (state = null, action: any): ITextState | any => {
  switch (action.type) {
    case TextActionEnum.RECEIVE_ALL: {
      return action.payload;
    }
    default:
      return state;
  }
};
