import * as React from "react";
import "./GlobalError.scss";

export interface IGlobalErrorProps {
  correlationId?: string;
}

export interface IAlertComponentProps {
  header: string;
  correlationId?: string;
  isWarning?: boolean;
}

export const AlertComponent: React.FC<IAlertComponentProps> = props => {
  const RenderReferenceNumber = (id: string) => {
    if (id) {
      return (
        <p>
          <i>Reference kode: {id}</i>
        </p>
      );
    }
    return;
  };

  return (
    <div className="container">
      <div className="row">
        <div
          className={"alert " + (props.isWarning ? "alert-warning" : "alert-danger") + " col-xs-12 col-sm-12  col-lg-10 offset-lg-1 col-xl-8 offset-xl-2"}
          role="alert"
        >
          <h4 className="alert-heading">{props.header}</h4>
          {props.children}
          {props.correlationId && RenderReferenceNumber(props.correlationId)}
        </div>
      </div>
    </div>
  );
};
