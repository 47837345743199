import "./CvrWidget.scss";
import React from "react";
import { WidgetProps } from "react-jsonschema-form";
import { Input, InputGroup } from "reactstrap";
import { ICompanyResult } from "../../../containers/message/models/ICompanyResult";
import AutoCompleteFieldService from "../../../components/inner-components/message-info/AutoCompleteFieldService";
import CompanyInfo from "./CompanyInfo";

export const CvrWidget: React.FC<WidgetProps> = ({ id, formContext, schema, onChange, value, disabled }: React.PropsWithChildren<WidgetProps>) => {
  const onCvrChange = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    const cvr = e.currentTarget.value;

    onChange(cvr.length > 0 ? cvr : undefined);
  };

  const onCvrAutoCompleteClick = (companyResult: ICompanyResult) => {
    const company = companyResult?.company;
    if (company) {
      if (formContext.onNotifyAutoCompleteFields && formContext.changeEvent) {
        const eventArgs = formContext.changeEvent;
        if (eventArgs.formData) {
          const acfs = new AutoCompleteFieldService(id, eventArgs, schema);
          acfs.setFieldValue("cvrCompanyNameField", company.name);
          acfs.setFieldValue("cvrCompanyAddressNameField", company.addressName);
          acfs.setFieldValue(
            "cvrCompanyAddressNumberField",
            company.addressNumber ? company.addressNumber + (company.addressLetter ? company.addressLetter : "") : undefined
          );
          acfs.setFieldValue("cvrCompanyAddressFloorField", company.addressFloor ? company.addressFloor : undefined);
          acfs.setFieldValue("cvrCompanyAddressDoorField", company.addressDoor ? company.addressDoor : undefined);
          acfs.setFieldValue("cvrCompanyZipField", company.zip);
          acfs.setFieldValue("cvrCompanyCityField", company.city);
          formContext.onNotifyAutoCompleteFields(eventArgs);
        }
      }
    }
  };

  return (
    <div className="cvr-widget">
      <InputGroup>
        <Input value={value || ""} onChange={onCvrChange} id={id} disabled={disabled} />
      </InputGroup>
      <CompanyInfo
        value={value}
        eanNumberMode={false}
        hasAutoCompleteFields={new AutoCompleteFieldService(id, formContext.changeEvent, schema).hasAutoCompleteFields([
          "cvrCompanyNameField",
          "cvrCompanyAddressNameField",
          "cvrCompanyAddressNumberField",
          "cvrCompanyAddressFloorField",
          "cvrCompanyAddressDoorField",
          "cvrCompanyZipField",
          "cvrCompanyCityField",
        ])}
        onAutoCompleteFieldsClick={onCvrAutoCompleteClick}
      />
    </div>
  );
};
