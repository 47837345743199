import * as React from "react";
import { HasClaimPrivilege, IsTeamMember, StatstidendePrivileges } from "../../../services/ClaimService";
import { IStatstidendeJwtToken } from "../../../reducers/user/types";
import ClickAwayListener from "react-click-away-listener";
import { AdminNavigationItems, EditorNavigationItems, UserNavigationItems } from "../menu/UserNavigationList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IUserActions, IUserState, UserActions } from "../../../reducers/user";

interface IMenuMainProps extends IUserActions {
  token: IStatstidendeJwtToken;
  onClose?: () => void;
  onLogout: () => void;
  user: IUserState;
}

class DropDownMenuBuilder extends React.Component<IMenuMainProps, {}> {
  public render() {
    const { token, onLogout, user } = this.props;
    const { dkstatstidendename } = token;

    const linkToTeams = "?teams=" + user.profile?.teams?.map(x => x.id)?.join("&teams=");

    return (
      <ClickAwayListener onClickAway={this.props.onClose!}>
        <div onClick={this.props.onClose!} className="main-drop" aria-labelledby="navbarDropdown">
          <ul>
            <li className="category">{`${dkstatstidendename}`}</li>
            <UserNavigationItems linkToTeams={linkToTeams} />
            <li>
              <a onClick={onLogout}>Log ud</a>
            </li>
            {(HasClaimPrivilege(StatstidendePrivileges.AdminMessageType) ||
              HasClaimPrivilege(StatstidendePrivileges.AdminUsers) ||
              HasClaimPrivilege(StatstidendePrivileges.AdminNews) ||
              HasClaimPrivilege(StatstidendePrivileges.AccessInvoiceFiles) ||
              HasClaimPrivilege(StatstidendePrivileges.AdminOwnTeams)) && <li className="category">Administration</li>}
            <AdminNavigationItems />
            {HasClaimPrivilege(StatstidendePrivileges.AccessProductionCalendar) && <li className="category">Redaktion</li>}
            <EditorNavigationItems />
          </ul>
        </div>
      </ClickAwayListener>
    );
  }
}

export default connect(
  ({ user }: any, ownProps: any) => ({ ...ownProps, user }),
  dispatch => bindActionCreators({ ...UserActions }, dispatch)
)(DropDownMenuBuilder);
