import { ActionsUnion, createAction } from "../actionHelper";
import { ParsedParams, parseWithDefaults, parseQueryString, IParsable } from "@lexdania/components";

const SET_SEARCHMESSAGELOGPARAMS = "SET_SEARCHMESSAGELOGPARAMS";

export interface IMessageLogSearchParams extends IParsable {
  page: number;
  ps: number;
  d: boolean;
  userId: number;
  fromDate: string;
  toDate: string;
}

export interface IMessageLogSearchState {
  params: string;
  paramsParsed: ParsedParams;
  parseWithDefaults: (defaults: IMessageLogSearchParams) => IMessageLogSearchParams;
}

export const setSearchParams = (item: string) => createAction(SET_SEARCHMESSAGELOGPARAMS, item);

// Action Exports
export interface ISearchMessageLogActions {
  setSearchParams: (params: string) => void;
}

export const SearchMessageLogActions: ISearchMessageLogActions = {
  setSearchParams,
};

const defaultState: IMessageLogSearchState = {
  params: "",
  paramsParsed: {},
  parseWithDefaults: defaults => parseWithDefaults({}, defaults), // !Important to keep this as it does a spread of the "empty marker arrays" EmptyNumberArray and EmptyNumberArray
};

const Actions = { setSearchParams };
type SearchMessageLogActions = ActionsUnion<typeof Actions>;

export default (state: IMessageLogSearchState = defaultState, action: SearchMessageLogActions): IMessageLogSearchState => {
  switch (action.type) {
    case SET_SEARCHMESSAGELOGPARAMS:
      const params = action.payload;
      const parsed: ParsedParams = parseQueryString(params);
      return {
        ...state,
        params: params,
        paramsParsed: parsed,
        parseWithDefaults: defaults => parseWithDefaults(parsed, defaults),
      };
    default:
      return state;
  }
};
