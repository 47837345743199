import axios, { AxiosError } from "axios";
import { ResultStateCode } from "../reducers/editMessage/EditMessageActions";
import Storage from "./../reducers/user/Storage";

export interface IClientError {
  correlationId: string;
  messageTemplate: string;
  stackTrace?: string;
  requestUrl?: string;
}

export enum AxiosActionCode {
  Ok = 10,
  ApiError = 20,
  ShowModalErrorOldConcurrencyToken = 30,
  ShowBusinessErrorModal = 40,
}

export class AxiosService {
  public static setupAxios(callback: any) {
    this.setupAxiosGlobalRequest(callback);
    this.setupAxiosGlobalResponse(callback);
  }

  // Service with custom header, to allow cross site requests
  public static async externalLogToServer(clientError: IClientError): Promise<string> {
    this.logToServer(clientError);
    return new Promise<string>(resolve => {
      resolve("Done");
    });
  }

  public static setupHeaders() {
    // Performs api calls sending the required authentication headers
    const defaultHeaders = {
      pragma: "no-cache",
      "cache-control": "no-cache",
    };
    const headers: any = Storage.HasValidToken() ? { ...defaultHeaders, Authorization: "Bearer " + Storage.JWTGetTokenString() } : defaultHeaders;

    return headers;
  }

  private static setupAxiosGlobalRequest(callback: any) {
    // Add a request interceptor
    axios.interceptors.request.use(
      config => {
        // Do something before request is sent

        config.headers = this.setupHeaders();

        return config;
      },
      (error: AxiosError) => {
        if (error.response) {
          callback(AxiosActionCode.ApiError, error.response.status, error.request.headers["x-correlation-id"]);
        } else {
          callback(AxiosActionCode.ApiError, null, error.request.headers["x-correlation-id"]);
        }

        const clientError: IClientError = {
          correlationId: error.request.headers["x-correlation-id"],
          messageTemplate: error.message,
          stackTrace: error.stack,
          requestUrl: error.request.responseURL,
        };

        this.logToServer(clientError);

        return Promise.reject(error);
      }
    );
  }

  private static setupAxiosGlobalResponse(callback: (axiosActionCode: AxiosActionCode, status: number, correlationId: string, errors?: string[]) => void) {
    // Add a response interceptor
    axios.interceptors.response.use(
      response => {
        if (response.data.isFailure && response.data.resultStateCode === ResultStateCode.ErrorOldConcurrencyToken) {
          callback(AxiosActionCode.ShowModalErrorOldConcurrencyToken, response.status, response.headers["x-correlation-id"]);
        }

        if (response.data.isFailure && response.data.resultStateCode === ResultStateCode.BusinessError) {
          callback(AxiosActionCode.ShowBusinessErrorModal, response.status, response.headers["x-correlation-id"], response.data.errors);
        }

        callback(AxiosActionCode.Ok, response.status, response.headers["x-correlation-id"]);
        return response;
      },
      (error: AxiosError) => {
        if (error !== undefined && error.response !== undefined) {
          callback(AxiosActionCode.ApiError, error.response.status, error.response.headers["x-correlation-id"]);

          const clientError: IClientError = {
            correlationId: error.response.headers["x-correlation-id"],
            messageTemplate: error.message,
            stackTrace: error.stack,
            requestUrl: error.request.responseURL,
          };

          this.logToServer(clientError);
        }

        return Promise.reject(error);
      }
    );
  }

  private static logToServer = (clientError: IClientError) => {
    if (!Storage.HasValidToken()) {
      return;
    }
    const axiosHttpLogClient = axios.create();
    axiosHttpLogClient.interceptors.request.use(config => {
      const defaultHeaders = AxiosService.setupHeaders();
      config.headers = clientError.correlationId ? { ...defaultHeaders, "x-correlation-id": clientError.correlationId } : defaultHeaders;
      return config;
    });

    axiosHttpLogClient.post("api/log/logerror", clientError);
  };
}
