import * as React from "react";

/* https://reacttraining.com/react-router/web/guides/scroll-restoration */
export class ScrollToTopOnMount extends React.Component {
  public componentDidMount() {
    window.scrollTo(0, 0);
  }

  public componentDidUpdate() {
    window.scrollTo(0, 0);
    return false;
  }

  public render() {
    return null;
  }
}
