import * as React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IUserActions, UserActions } from "../../reducers/user";

interface ILogoutHandlerProps extends IUserActions, RouteComponentProps<{}> {}

class LogoutHandler extends React.Component<ILogoutHandlerProps, {}> {
  public componentDidMount() {
    const hasBeenDeleted = localStorage.getItem("isDeleted") === "true";
    localStorage.removeItem("isDeleted");
    if (hasBeenDeleted) {
      this.props.userDeletedAndLoggedOut();
    } else {
      this.props.userLoggedOut();
    }
  }

  public render() {
    return <Redirect to="/" />;
  }
}

export default connect(
  ({ user }: any, ownProps: any) => ({ ...ownProps, user }),
  dispatch => bindActionCreators({ ...UserActions }, dispatch)
)(LogoutHandler);
