import { IEditMessageState } from "./IEditMessageState";
import { EditMessageActionsTypes as ActionType } from "./EditMessageActions";

export const InitialState: IEditMessageState = {
  messageTypeId: -1,
  messageNumber: "",
  messageState: { value: -1, displayName: "" },
  fieldsData: undefined,
  publicationPatterns: undefined,
  messageTypeSchema: undefined,
  defaultFieldsData: undefined,
  isNewStart: false,
  messageValidationResult: undefined,
  isSavingMessage: false,
  correctionOfMessageNumber: undefined,
  concurrencyToken: undefined,
};

export default (state = InitialState, action: any): IEditMessageState => {
  switch (action.type) {
    case ActionType.CLEAR_FIELD_SCHEMA:
      return { ...state, ...{ fieldSchema: null } };
    case ActionType.SET_DEFAULT_FIELDS_DATA:
      return { ...state, ...action.payload };
    case ActionType.SET_PUBLICATiON_PATTERNS:
      return { ...state, ...{ publicationPatterns: action.payload } };
    case ActionType.SET_FIELDS_DATA:
      return { ...state, ...action.payload };
    case ActionType.SAVED_EDIT_MESSAGE:
      return {
        ...state,
        ...{
          messageNumber: action.payload.messageNumber,
          messageValidationResult: action.payload,
          isSavingMessage: false,
        },
      };
    case ActionType.REQUEST_SAVE_MESSAGE:
      return { ...state, ...{ isSavingMessage: true } };
    case ActionType.RECEIVE_EDIT_MESSAGE: {
      const { message, messageTypeSchema } = action.payload;
      const { fieldsData, defaultFieldsData, messageTypeId, messageNumber, state: payloadState, correctionOfMessageNumber, concurrencyToken } = message;
      return {
        ...state,
        ...{
          messageTypeSchema,
          fieldsData: fieldsData ? JSON.parse(fieldsData) : {},
          defaultFieldsData: defaultFieldsData ? JSON.parse(defaultFieldsData) : {},
          messageTypeId,
          messageNumber,
          messageState: payloadState,
          correctionOfMessageNumber,
          concurrencyToken,
          messageValidationResult: undefined,
        },
      };
    }
    case ActionType.RECEIVE_SET_MESSAGE_TYPE: {
      const { message, messageTypeSchema } = action.payload;
      const { fieldsData, defaultFieldsData, messageTypeId, messageNumber, state: messageState } = message;
      return {
        ...state,
        ...{
          messageTypeSchema,
          fieldsData: fieldsData ? JSON.parse(fieldsData) : {},
          defaultFieldsData: defaultFieldsData ? JSON.parse(defaultFieldsData) : {},
          messageTypeId,
          messageNumber,
          messageState,
        },
      };
    }
    case ActionType.CLEAR_FLOW:
      const { publicationPatterns, ...rest } = InitialState;
      return { ...rest, ...{ publicationPatterns: state.publicationPatterns } };
    default:
      return state;
  }
};
