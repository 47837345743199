import { createAction, IActionWithPayload } from "../actionHelper";
import { ParsedParams, parseWithDefaults, parseQueryString, IParsable, PageSize } from "@lexdania/components";

// Action Constants
const SET_SEARCHPARAMS = "SET_SEARCHPARAMS";

export interface ISearchParams extends IParsable {
  page: number;
  ps: PageSize;
  o: number;
  d: boolean;
  userOnly: boolean;
  s: string[];
  messagesforuser: string;
  t: string[];
  teams: string[];
  fromDate: string;
  toDate: string;
  fld: string[];
  m: string[];
  j: string[];
}

export interface ISearchState {
  params: string;
  paramsParsed: ParsedParams;
  parseWithDefaults: (defaults: ISearchParams) => ISearchParams;
}

// Actions
const setSearchParams = (params: string) => createAction(SET_SEARCHPARAMS, params);

// Action Exports
export interface ISearchActions {
  setSearchParams: (params: string) => void;
}

export const SearchActions: ISearchActions = {
  setSearchParams,
};

const defaultState: ISearchState = {
  params: "",
  paramsParsed: {},
  parseWithDefaults: defaults => parseWithDefaults({}, defaults), // !Important to keep this as it does a spread of the "empty marker arrays" EmptyNumberArray and EmptyNumberArray
};

export default (state: ISearchState = defaultState, action: IActionWithPayload<string, string>): ISearchState => {
  switch (action.type) {
    case SET_SEARCHPARAMS: {
      const params = action.payload;
      const parsed: ParsedParams = parseQueryString(params);
      return {
        ...state,
        params: params,
        paramsParsed: parsed,
        parseWithDefaults: defaults => parseWithDefaults(parsed, defaults),
      };
    }
    default:
      return state;
  }
};
