import { JSONSchema6 } from "json-schema";
import { IChangeEvent } from "react-jsonschema-form";

/*
This class is responsible for changing field data outside a widget.

IMPORTANT: This class cannot handle more than one depth of field structure.
 */
class AutoCompleteFieldService {
  private readonly fieldGroupId: string;
  private readonly fieldGroupIndex?: number;
  private readonly fieldGroupIsArray: boolean;
  private readonly formData: any;
  private readonly schema: any;

  constructor(id: string, changeEvent: IChangeEvent, widgetSchema: JSONSchema6) {
    this.formData = changeEvent.formData;
    this.schema = widgetSchema;
    const idParts = id.split("_");
    this.fieldGroupId = idParts[1];
    this.fieldGroupIsArray = idParts.length === 4;
    if (this.fieldGroupIsArray) {
      this.fieldGroupIndex = Number.parseInt(idParts[2], 10);
    }
  }
  public hasAutoCompleteFields = (fields: string[]) => {
    return fields.some(field => {
      const fieldId = this.schema[field];
      if (fieldId === undefined) {
        return false;
      }

      if (document.getElementById(this.getElementId(fieldId))) return true;

      if (this.formData && this.formData[this.fieldGroupId]) {
        if (this.fieldGroupIsArray && this.fieldGroupIndex !== undefined) {
          if (this.formData[this.fieldGroupId][this.fieldGroupIndex] && this.formData[this.fieldGroupId][this.fieldGroupIndex][fieldId]) return true;
        } else {
          if (this.formData[this.fieldGroupId][fieldId]) return true;
        }
      }

      return false;
    }, this);
  };

  public setFieldValue = (field: string, value: any) => {
    const fieldId = this.schema[field];
    if (fieldId === undefined) {
      return;
    }
    if (value === undefined) {
      (document.getElementById(this.getElementId(fieldId)) as HTMLInputElement).value = "";
      return;
    }
    if (this.fieldGroupIsArray && this.fieldGroupIndex !== undefined) {
      this.formData[this.fieldGroupId][this.fieldGroupIndex][fieldId] = value;
    } else {
      this.formData[this.fieldGroupId][fieldId] = value;
    }
    this.setElementValue(fieldId, value);
  };

  private getElementId = (fieldId: string) =>
    this.fieldGroupIsArray ? `root_${this.fieldGroupId}_${this.fieldGroupIndex}_${fieldId}` : `root_${this.fieldGroupId}_${fieldId}`;

  private setElementValue = (fieldId: string, value: any) => {
    const elementID = this.getElementId(fieldId);
    const element = document.getElementById(elementID) as HTMLInputElement;
    if (element) {
      element.value = value;
      // Make sure event listeners can be notified about changes in the value
      const event = new Event("change", { bubbles: true });
      element.dispatchEvent(event);
    }
  };
}

export default AutoCompleteFieldService;
