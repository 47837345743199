import { IMessageState } from "../../components/viewmodels/MessageModels";
import { IMessageValidationResult } from "./EditMessageAPI";

export enum PublicationPatternEnum {
  DailyPublications = 1,
  QuarterlyPublications = 2,
}

export interface IPublicationPattern {
  id: number;
  publicationPatternId: number;
}

export interface IMessageTypeSchema {
  sectionName: string;
  fieldsSchema: any;
  fieldsUiSchema: any;
  defaultFieldsSchema: any;
  defaultFieldsUiSchema: any;
}

export interface IEditMessageState {
  messageTypeId: number;
  publicationPatterns?: IPublicationPattern[];
  messageNumber: string;
  fieldSchema?: any;
  messageTypeSchema?: IMessageTypeSchema;
  fieldsData?: object;
  defaultFieldsData?: object;
  messageState: IMessageState;
  isNewStart: boolean;
  messageValidationResult?: IMessageValidationResult;
  isSavingMessage: boolean;
  correctionOfMessageNumber?: string;
  concurrencyToken?: Uint32Array[];
}
export enum DateRuleType {
  BeforeOrEqualToday = 1,
  EqualOrAfterToday = 2,
}
