import * as React from "react";
import "./FileWithThumbnailWidget.scss";
import { WidgetProps } from "react-jsonschema-form";
import { ErrorModal } from "@lexdania/components";

const AcceptedImageWidth: number = 0; // Set to 0 after talk with Kasper (KVM)
const AcceptedImageTypes: string[] = ["image/gif", "image/jpeg", "image/png"];

const ErrorOnNonAcceptedImageWidthText: string = "Billedet skal være større end 1400px.";

export const ErrorOnNonAcceptedImageTypesText: string = "Du kan kun vælge billedtyper af .png, .jpg, .jpeg og .gif";

interface IFileWithThumbnailWidgetState {
  file?: File;
  imgsrc?: string;
  fileValue?: string;
  fileInvalid: boolean;
  fileInvalidErrorText: string;
  fileShow: boolean;
}

export class FileWithThumbnailWidget extends React.Component<WidgetProps, IFileWithThumbnailWidgetState> {
  constructor(props: WidgetProps) {
    super(props);

    this.state = {
      imgsrc: this.props.value,
      fileInvalid: false,
      fileInvalidErrorText: "",
      fileShow: false,
    };
  }

  public componentDidUpdate() {
    if (this.state.imgsrc === this.props.value) {
      return;
    }
    this.setState({ imgsrc: this.props.value });
  }

  public onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const currentFile = e.currentTarget.files !== null && e.currentTarget.files.length > 0 ? e.currentTarget.files[0] : undefined;

    if (currentFile !== undefined && !this.matchAcceptedTypes(currentFile.type)) {
      this.setState(
        {
          fileValue: undefined,
          fileInvalid: true,
          fileInvalidErrorText: ErrorOnNonAcceptedImageTypesText,
        },
        this.resetFileForm
      );
      return;
    }

    const currentImg = currentFile !== undefined ? window.URL.createObjectURL(currentFile) : undefined;
    this.setState(
      {
        file: currentFile,
        imgsrc: currentImg,
        fileValue: e.currentTarget.value,
        fileInvalid: false,
        fileInvalidErrorText: "",
      },
      () => {
        this.setImageAsDataUrlInForm(currentFile);
      }
    );
  };

  public onRemoveImageClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.resetFileForm();
  };

  public isRemoveButtonHidden = (): boolean => {
    return this.state.imgsrc === undefined || this.state.imgsrc === "" || !this.state.fileShow;
  };

  public onModalConfirm = (e: any): void => {
    this.setState({
      fileInvalid: false,
      fileShow: false,
      fileInvalidErrorText: "",
    });
  };

  public onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (e.currentTarget.naturalWidth >= AcceptedImageWidth) {
      this.setState({ fileShow: true });
      return;
    }
    this.setState(
      {
        fileInvalid: true,
        fileShow: false,
        fileInvalidErrorText: ErrorOnNonAcceptedImageWidthText,
      },
      this.resetFileForm
    );
  };

  public render() {
    const fileName = this.state.file !== undefined && this.state.file.name !== null && this.state.fileShow ? this.state.file.name : "Vælg billede...";
    const fileValue = this.state.fileValue === undefined ? "" : this.state.fileValue;
    return (
      <React.Fragment>
        <div className="form-group row custom-file-widget">
          <div className="col-12">
            <div className="input-group mb-3">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="fileInput"
                  onChange={this.onChange}
                  value={fileValue}
                  tabIndex={0}
                  disabled={this.props.disabled}
                />
                <label className="custom-file-label" htmlFor="fileInput">
                  {fileName}
                </label>
              </div>
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.onRemoveImageClick}
                  hidden={this.isRemoveButtonHidden()}
                  disabled={this.props.disabled}
                >
                  Fjern
                </button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <img src={this.state.imgsrc} alt="" onLoad={this.onImageLoad} hidden={!this.state.fileShow} />
          </div>
        </div>
        <ErrorModal onConfirm={this.onModalConfirm} errorText={this.state.fileInvalidErrorText} showModal={this.state.fileInvalid} />
      </React.Fragment>
    );
  }

  private removeThumbnail = (): void => {
    if (this.state.imgsrc === undefined) {
      return;
    }
    window.URL.revokeObjectURL(this.state.imgsrc);
  };

  private resetFileForm = (): void => {
    this.removeThumbnail();
    this.setState(
      {
        file: undefined,
        fileShow: false,
        imgsrc: undefined,
        fileValue: undefined,
      },
      () => {
        this.props.onChange(undefined);
      }
    );
  };

  private setImageAsDataUrlInForm = (file?: File): void => {
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        this.removeThumbnail();
        this.props.onChange(reader.result);
      },
      false
    );

    if (file === undefined) {
      return;
    }
    reader.readAsDataURL(file);
  };

  private matchAcceptedTypes(type: string): boolean {
    return AcceptedImageTypes.some(value => {
      return value === type;
    });
  }
}
