import * as React from "react";
import { Link } from "react-router-dom";
import { AlertComponent, IGlobalErrorProps } from "./AlertComponent";
import Helmet from "react-helmet";

const Forbidden: React.FC<IGlobalErrorProps> = (props: IGlobalErrorProps) => {
  document.title = "Statstidende | Adgang nægtet";

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <AlertComponent header="Adgang nægtet" correlationId={props.correlationId}>
        <p>Du har desværre ikke adgang til den resource som du prøver at tilgå.</p>
        <p>
          Venligst <Link to="/contact">kontakt</Link> administrationen hvis du mener det er en fejl.
        </p>
        <p>Forsøget på adgang er registreret.</p>
      </AlertComponent>
    </>
  );
};

export default Forbidden;
