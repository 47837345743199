import * as React from "react";
import { IEditMessageState } from "../../../reducers/editMessage/IEditMessageState";
import { DateFormats } from "@lexdania/components";
import { parse } from "date-fns";
import { DateRuleType } from "../../../reducers/editMessage/IEditMessageState";
import { ISelectableDate } from "./ISelectableDate";
import { UiSchema } from "react-jsonschema-form";

interface IDateValidatorProps {
  editMessage: IEditMessageState;
  children: any;
}

export default class DateValidator extends React.Component<IDateValidatorProps, {}> {
  public render() {
    return <React.Fragment>{this.props.children(this.validate)}</React.Fragment>;
  }

  private getUiSchemaValue = (
    uiSchema: any,
    fieldgroup: string,
    field: string,
    uiSchemaProperty: string,
    subProperty: string | undefined
  ): string | undefined => {
    const fieldGroupObject = uiSchema[fieldgroup];
    if (!fieldGroupObject) {
      return undefined;
    }

    const fieldObject = fieldGroupObject.hasOwnProperty("items") ? fieldGroupObject.items[field] : fieldGroupObject[field];
    if (!fieldObject) {
      return undefined;
    }

    const optionsObject = fieldObject.hasOwnProperty("items") ? fieldObject.items[uiSchemaProperty] : fieldObject[uiSchemaProperty];
    if (!optionsObject) {
      return undefined;
    }

    if (subProperty) {
      return optionsObject[subProperty];
    }

    return optionsObject;
  };

  private isValidPublicationDate = (chosenPublicationDate: Date, selectableDates: ISelectableDate[] | undefined): boolean => {
    if (!selectableDates || selectableDates.length === 0) {
      return true;
    }

    return selectableDates.some(x => parse(x.date, DateFormats.Default, new Date()).getTime() === chosenPublicationDate.getTime());
  };

  private validateDateRule = (fieldValueAsDate: Date, dateRule: number): string | undefined => {
    switch (dateRule) {
      case DateRuleType.BeforeOrEqualToday:
        if (fieldValueAsDate > new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
          return "Datoen skal være før eller lig med dags dato";
        }
        break;
      case DateRuleType.EqualOrAfterToday:
        if (fieldValueAsDate < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
          return "Datoen skal være efter eller lig med dags dato";
        }
        break;
      default:
        break;
    }
    return undefined;
  };

  public validate = (formData: any, errors: any, uiSchema: UiSchema): any => {
    const addError = (fieldgroup: string, fieldGroupIndex: string, field: string, fieldIndex: string, errorMessage: string) => {
      if (errors[fieldgroup].hasOwnProperty(fieldGroupIndex)) {
        if (errors[fieldgroup][fieldGroupIndex][field].hasOwnProperty(fieldIndex)) {
          errors[fieldgroup][fieldGroupIndex][field][fieldIndex].addError(errorMessage);
        } else {
          errors[fieldgroup][fieldGroupIndex][field].addError(errorMessage);
        }
      } else {
        if (errors[fieldgroup][field].hasOwnProperty(fieldIndex)) {
          errors[fieldgroup][field][fieldIndex].addError(errorMessage);
        } else {
          errors[fieldgroup][field].addError(errorMessage);
        }
      }
    };
    const getValidPublicationDates = (fieldgroup: string, field: string): ISelectableDate[] | undefined => {
      const dates = this.getUiSchemaValue(uiSchema, fieldgroup, field, "ui:options", "validDates");
      if (dates != null) {
        return JSON.parse(dates);
      }
      return undefined;
    };

    for (const fieldgroup in formData) {
      if (!errors.hasOwnProperty(fieldgroup)) {
        continue;
      }

      const fieldGroupIterator: [any] = Array.isArray(formData[fieldgroup]) ? formData[fieldgroup] : [formData[fieldgroup]]; // If there is at least one fieldgroup by indexer, it is array
      for (const fieldGroupIndex in fieldGroupIterator) {
        const fieldGroupObject = fieldGroupIterator[fieldGroupIndex];
        for (const field in fieldGroupObject) {
          if (!fieldGroupObject.hasOwnProperty(field)) {
            continue;
          }

          const widgetName = this.getUiSchemaValue(uiSchema, fieldgroup, field, "ui:widget", undefined);

          const fieldValues = Array.isArray(fieldGroupObject[field]) ? fieldGroupObject[field] : [fieldGroupObject[field]];
          for (const fieldValueIndex in fieldValues) {
            const fieldValue = fieldValues[fieldValueIndex];
            if (widgetName === "customPublicationDateWidget") {
              const fieldValueAsDate = parse(fieldValue, DateFormats.Default, new Date());
              const validPublicationDates = getValidPublicationDates(fieldgroup, field);
              if (!this.isValidPublicationDate(fieldValueAsDate, validPublicationDates)) {
                addError(fieldgroup, fieldGroupIndex, field, fieldValueIndex, "Datoen er ikke en gyldig kundgørelsesdato");
              }
            }

            if (widgetName === "customDateWidget") {
              const fieldValueAsDate = parse(fieldValue, DateFormats.Default, new Date());
              const dateRule = this.getUiSchemaValue(uiSchema, fieldgroup, field, "ui:options", "dateRule");
              if (dateRule) {
                const validationString = this.validateDateRule(fieldValueAsDate, +dateRule);
                if (validationString) {
                  addError(fieldgroup, fieldGroupIndex, field, fieldValueIndex, validationString);
                }
              }
            }
          }
        }
      }
    }

    return errors;
  };
}
