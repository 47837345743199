import { createAction } from "../actionHelper";
import { apiGET } from "../../api";
import { INewsItemModel } from "./newsModels";

export enum NewsAction {
  RECEIVE_ACTIVE_NEWS = "RECEIVE_ACTIVE_NEWS",
}

export interface INewsState {
  newsItems?: INewsItemModel[];
}

const receiveActiveNews = (newsItems: INewsItemModel[]) => createAction(NewsAction.RECEIVE_ACTIVE_NEWS, newsItems);

const getActiveNewsItems = () => async (dispatch: any, getState: any) => {
  const news = getState().news;

  if (news.newsItems) {
    return;
  }

  dispatch(receiveActiveNews(await apiGET<INewsItemModel[]>("api/news/GetAllActive")));
};

export const NewsActions = { getActiveNewsItems };

export default (state: INewsState = {}, action: any): INewsState => {
  switch (action.type) {
    case NewsAction.RECEIVE_ACTIVE_NEWS: {
      return { ...state, ...{ newsItems: action.payload } };
    }
    default:
      return state;
  }
};
