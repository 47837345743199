import { ActionsUnion, createAction } from "../actionHelper";
import { ParsedParams, parseWithDefaults, parseQueryString, IParsable } from "@lexdania/components";

const SET_SEARCHTEAMSPARAMS = "SET_SEARCHTEAMSPARAMS";

export interface ITeamsSearchParams extends IParsable {
  page: number;
  ps: number;
  t: string[];
  o: number;
  d: boolean;
  isEnabled: boolean;
  isDisabled: boolean;
  s: string[];
}

export interface ITeamsSearchState {
  params: string;
  paramsParsed: ParsedParams;
  parseWithDefaults: (defaults: ITeamsSearchParams) => ITeamsSearchParams;
}

export const setSearchParams = (item: string) => createAction(SET_SEARCHTEAMSPARAMS, item);

// Action Exports
export interface ISearchTeamsActions {
  setSearchParams: (params: string) => void;
}

export const SearchTeamsActions: ISearchTeamsActions = {
  setSearchParams,
};

const defaultState: ITeamsSearchState = {
  params: "",
  paramsParsed: {},
  parseWithDefaults: defaults => parseWithDefaults({}, defaults), // !Important to keep this as it does a spread of the "empty marker arrays" EmptyNumberArray and EmptyNumberArray
};

const Actions = { setSearchParams };
type SearchTeamsActions = ActionsUnion<typeof Actions>;

export default (state: ITeamsSearchState = defaultState, action: SearchTeamsActions): ITeamsSearchState => {
  switch (action.type) {
    case SET_SEARCHTEAMSPARAMS:
      const params = action.payload;
      const parsed: ParsedParams = parseQueryString(params);
      return {
        ...state,
        params: params,
        paramsParsed: parsed,
        parseWithDefaults: defaults => parseWithDefaults(parsed, defaults),
      };
    default:
      return state;
  }
};
