import { take, call, fork, select, put } from "redux-saga/effects";
import { apiPOST, apiGET } from "../../api";
import { IMessage, IMessageLogInfo, MessageCorrectionType } from "../../components/viewmodels/MessageModels";
import { createAction } from "./../actionHelper";
import { EditMessageActionsTypes, ResultStateCode } from "../editMessage/EditMessageActions";

// Action constants
const REQUEST_CHANGE_STATE = "REQUEST_CHANGE_STATE";
const SET_STATE_VALIDITY = "SET_STATE_VALIDITY";
const REQUEST_RETURN_TO_OWNER = "REQUEST_RETURN_TO_OWNER";
const REQUEST_UNDO_RETURN_TO_OWNER = "REQUEST_UNDO_RETURN_TO_OWNER";
const REQUEST_CORRECT_MESSAGE = "REQUEST_CORRECT_MESSAGE";
const REQUEST_COPY_MESSAGE = "REQUEST_COPY_MESSAGE";
const RECEIVE_COPY_MESSAGE = "RECEIVE_COPY_MESSAGE";
const REQUEST_DELETE_DRAFT = "REQUEST_DELETE_DRAFT";
const RECEIVE_DELETE_DRAFT = "RECEIVE_DELETE_DRAFT";
const REQUEST_GET_MESSAGE = "REQUEST_GET_MESSAGE";
const RECEIVE_GET_MESSAGE = "RECEIVE_GET_MESSAGE";
const REQUEST_UPDATE_INTERNAL_COMMENT = "REQUEST_UPDATE_INTERNAL_COMMENT";
const RECEIVE_UPDATE_INTERNAL_COMMENT = "REQUEST_UPDATE_INTERNAL_COMMENT";
const REQUEST_TOGGLE_PRICE = "REQUEST_TOGGLE_PRICE";
const REQUEST_TOGGLE_SEARCHABLE = "REQUEST_TOGGLE_SEARCHABLE";
const REQUEST_TOGGLE_CORRECTION_TYPE = "REQUEST_SET_CORRECTION_TYPE";

// Response interfaces
export interface IMessageQueryResult {
  message: IMessage;
  logs: IMessageLogInfo[];
}

export interface IMessageValidationResult {
  isValid: boolean;
  messageNumber: string;
  errors: string[];
  resultStateCode: ResultStateCode;
}

// API calls
const POSTChangeState = (messageNumber: string, newState: number, concurrencyToken: Uint32Array[]) =>
  apiPOST<IMessageValidationResult>(`api/message/changemessagestate`, { messageNumber, newState, concurrencyToken });

const DELETEDraft = async (messageNumber: string, concurrencyToken: Uint8Array) =>
  apiPOST<IMessageQueryResult>(`api/message/delete`, { messageNumber, concurrencyToken });

const GETMessage = async (messageNumber: string): Promise<IMessageQueryResult> => GETBaseMessage(`api/message/${messageNumber}`);

const POSTReturnToOwner = async (messageNumber: string, comment: string, concurrencyToken: Uint32Array[]): Promise<IMessageQueryResult> =>
  apiPOST<IMessageQueryResult>(`api/message/returntoowner`, { messageNumber, comment, concurrencyToken });

const POSTUndoReturnToOwner = async (messageNumber: string, concurrencyToken: Uint32Array[]): Promise<IMessageQueryResult> =>
  apiPOST<IMessageQueryResult>(`api/message/undoreturntoowner`, { messageNumber, concurrencyToken });

const POSTUpdateInternalComment = (messageNumber: string, comment: string, concurrencyToken: Uint32Array[]) =>
  apiPOST<{}>(`api/message/comment`, { messageNumber, comment, concurrencyToken });

const POSTCorrectMessage = async (messageNumber: string, correctionType: MessageCorrectionType, concurrencyToken: Uint32Array[]): Promise<{}> =>
  apiPOST<{}>(`api/message/correct`, { messageNumber, correctionType, concurrencyToken });

const POSTCopyMessage = async (messageNumber: string, messageLogId?: number): Promise<IMessageValidationResult> =>
  apiPOST<IMessageValidationResult>(`api/message/copy`, { messageNumber, messageLogId });

const POSTToggleMessagePrice = async (messageNumber: string, concurrencyToken: Uint32Array[]) =>
  apiPOST<{}>(`api/message/toggleprice`, { messageNumber, concurrencyToken });

const POSTToggleSearchable = async (messageNumber: string, concurrencyToken: Uint32Array[]) =>
  apiPOST<{}>(`api/message/hidemessagefromsearches`, { messageNumber, concurrencyToken });

const POSTToggleCorrectionType = async (messageNumber: string, concurrencyToken: Uint32Array[]) =>
  apiPOST<{}>(`api/message/togglecorrectiontype`, { messageNumber, concurrencyToken });

const GETBaseMessage = async (url: string): Promise<IMessageQueryResult> => {
  const response = await apiGET<any>(url);
  const {
    document,
    sectionName,
    messageNumber,
    summaryFields,
    title,
    messageTypeName,
    messageTypeId,
    state,
    logs,
    isTeamsMessage,
    isMyMessage,
    returnToOwnerComment,
    price,
    isMessageSearchable,
    publicationDate,
    internalComment,
    ownerName,
    correctedBy,
    correctionOf,
    correctionOfExists,
    wasPublishedBeforeMigration,
    publicationId,
    correctionOfCorrectionType,
    correctedByCorrectionType,
    hasBeenReprintedAndCorrectingMessagesIsPublished,
    isCorrectedByAccessible,
    concurrencyToken,
  } = response;
  const msg = JSON.parse(document);
  const message: IMessage = {
    sectionName,
    messageTypeName,
    messageTypeId,
    messageNumber,
    summaryFields,
    fieldGroups: msg.fieldgroups,
    defaultFieldGroups: msg.defaultfieldgroups,
    title,
    state,
    isMyMessage,
    isTeamsMessage,
    returnToOwnerComment,
    price,
    isMessageSearchable,
    publicationDate,
    internalComment,
    ownerName,
    correctedBy,
    correctionOf,
    correctionOfExists,
    wasPublishedBeforeMigration,
    publicationId,
    correctionOfCorrectionType,
    correctedByCorrectionType,
    hasBeenReprintedAndCorrectingMessagesIsPublished,
    isCorrectedByAccessible,
    concurrencyToken,
  };

  const queryResult: IMessageQueryResult = { message, logs };
  return new Promise<IMessageQueryResult>(resolve => {
    resolve(queryResult);
  });
};

// Actions
const correctMessage = (correctionType: MessageCorrectionType) => createAction(REQUEST_CORRECT_MESSAGE, { correctionType });
const copyMessage = (messageLogId?: number) => createAction(REQUEST_COPY_MESSAGE, { messageLogId });
const receiveCopyMessage = (messageNumber: string) => createAction(RECEIVE_COPY_MESSAGE, messageNumber);
const resetCorrectMessageFeedback = () => createAction(SET_STATE_VALIDITY, { isValid: true, currentSetStateFeedBack: undefined });
const returnMessageToOwner = (comment: string) => createAction(REQUEST_RETURN_TO_OWNER, { comment });
const undoReturnMessageToOwner = () => createAction(REQUEST_UNDO_RETURN_TO_OWNER);
const changeState = (newState: number) => createAction(REQUEST_CHANGE_STATE, { newState });
const setStateValidity = (isValid: boolean, currentSetStateFeedBack: IMessageValidationResult) =>
  createAction(SET_STATE_VALIDITY, { isValid, currentSetStateFeedBack });
const setDeletedMessages = (messages: string[]) => createAction(RECEIVE_DELETE_DRAFT, messages);
const deleteDraft = (messageNumber: string, concurrencyToken: Uint32Array) => createAction(REQUEST_DELETE_DRAFT, { messageNumber, concurrencyToken });
const getMessage = (messageNumber: string) => createAction(REQUEST_GET_MESSAGE, messageNumber);
const receiveGetMessage = (messageQueryResult: IMessageQueryResult) => createAction(RECEIVE_GET_MESSAGE, messageQueryResult);
const updateInternalComment = (comment: string) => createAction(REQUEST_UPDATE_INTERNAL_COMMENT, { comment });
const receiveUpdateInternalComment = (comment: string) => createAction(RECEIVE_UPDATE_INTERNAL_COMMENT, comment);
const toggleMessagePrice = () => createAction(REQUEST_TOGGLE_PRICE);
const toggleSearchable = () => createAction(REQUEST_TOGGLE_SEARCHABLE);
const toggleCorrectionType = () => createAction(REQUEST_TOGGLE_CORRECTION_TYPE);

// Actions Interface
export interface IMessageActions {
  changeState: (newState: number) => void;
  deleteDraft: (messageNumber: string, concurrencyToken: Uint32Array) => void;
  getMessage: (messageNumber: string) => void;
  updateInternalComment: (comment: string) => void;
  returnMessageToOwner: (comment: string) => void;
  undoReturnMessageToOwner: () => void;
  correctMessage: (correctionType: MessageCorrectionType) => void;
  copyMessage: (messageLogId?: number) => void;
  toggleCorrectionType: () => void;
  resetCorrectMessageFeedback: () => void;
  toggleMessagePrice: () => void;
  toggleSearchable: () => void;
}

export const MessageActions: IMessageActions = {
  changeState,
  deleteDraft,
  getMessage,
  updateInternalComment,
  returnMessageToOwner,
  undoReturnMessageToOwner,
  correctMessage,
  copyMessage,
  resetCorrectMessageFeedback,
  toggleMessagePrice,
  toggleSearchable,
  toggleCorrectionType: toggleCorrectionType,
};

function* watchCorrect(): any {
  while (true) {
    const {
      payload: { correctionType },
    } = yield take(REQUEST_CORRECT_MESSAGE);
    const { messages } = yield select();
    const { message } = messages.current;
    const response: IMessageValidationResult = yield call(POSTCorrectMessage, message.messageNumber, correctionType, message.concurrencyToken);

    if (response.isValid) {
      const queryResult = yield call(GETMessage, response.messageNumber);
      yield put(receiveGetMessage(queryResult));
    } else {
      yield put(setStateValidity(response.isValid, response));
    }
  }
}

function* watchCopy() {
  while (true) {
    const {
      payload: { messageLogId },
    } = yield take(REQUEST_COPY_MESSAGE);
    const { messages } = yield select();
    const { message } = messages.current;
    const response: IMessageValidationResult = yield call(POSTCopyMessage, message.messageNumber, messageLogId);

    yield put(receiveCopyMessage(response.messageNumber));
  }
}

function* watchToggleCorrectionType() {
  while (true) {
    yield take(REQUEST_TOGGLE_CORRECTION_TYPE);
    const { messages } = yield select();
    const { message } = messages.current;
    const response: IMessageValidationResult = yield call(POSTToggleCorrectionType, message.messageNumber, message.concurrencyToken);
    if (response.isValid) {
      yield put(getMessage(message.messageNumber));
    }
  }
}

function* watchReturnToOwner() {
  while (true) {
    const {
      payload: { comment },
    } = yield take(REQUEST_RETURN_TO_OWNER);
    const { messages } = yield select();
    const { message } = messages.current;

    const response: IMessageValidationResult = yield call(POSTReturnToOwner, message.messageNumber, comment, message.concurrencyToken);

    if (response.isValid) {
      yield put(getMessage(message.messageNumber));
    }
  }
}

function* watchUndoReturnToOwner() {
  while (true) {
    yield take(REQUEST_UNDO_RETURN_TO_OWNER);
    const { messages } = yield select();
    const { message } = messages.current;
    const response: IMessageValidationResult = yield call(POSTUndoReturnToOwner, message.messageNumber, message.concurrencyToken);

    if (response.isValid) {
      yield put(getMessage(message.messageNumber));
    }
  }
}

function* watchRequestMessage(): any {
  while (true) {
    const { payload: messageNumber } = yield take(REQUEST_GET_MESSAGE);
    try {
      const queryResult = yield call(GETMessage, messageNumber);
      yield put(resetCorrectMessageFeedback());
      yield put(receiveGetMessage(queryResult));
    } catch {
      console.log("Message request failed");
    }
  }
}

function* watchDeleteDraft() {
  while (true) {
    var {
      payload: { messageNumber, concurrencyToken },
    } = yield take(REQUEST_DELETE_DRAFT);
    const { messages } = yield select();
    const response: IMessageValidationResult = yield call(DELETEDraft, messageNumber, concurrencyToken);
    if (response.isValid) {
      const { deletedMessages } = messages;
      // Add deleted message to list
      const newDeletedMessages = [...deletedMessages, messageNumber];
      // Put new list into reducer store
      yield put(setDeletedMessages(newDeletedMessages));
    }
  }
}

function* watchChangeState(): any {
  while (true) {
    const { payload }: any = yield take(REQUEST_CHANGE_STATE);
    const { messages } = yield select();
    const { message } = messages.current;
    const response: IMessageValidationResult = yield call(POSTChangeState, message.messageNumber, payload.newState, message.concurrencyToken);

    // If valid. Get message again
    // TODO: consider letting POSTChangeState returning the new message instead of only the number which was changed
    if (response.isValid) {
      yield put(getMessage(message.messageNumber));
    }
    yield put(setStateValidity(response.isValid, response));
  }
}

function* watchUpdateInternalComment() {
  while (true) {
    const {
      payload: { comment },
    } = yield take(REQUEST_UPDATE_INTERNAL_COMMENT);
    const { messages } = yield select();
    const { message } = messages.current;

    try {
      const response: IMessageValidationResult = yield call(POSTUpdateInternalComment, message.messageNumber, comment, message.concurrencyToken);
      if (response.isValid) {
        yield put(receiveUpdateInternalComment(message.comment));
        yield put(getMessage(message.messageNumber));
      }
    } catch {
      console.log("Add comment request failed");
    }
  }
}

function* watchToggleMessagePrice() {
  while (true) {
    yield take(REQUEST_TOGGLE_PRICE);
    const { messages } = yield select();
    const { message } = messages.current;

    try {
      const response: IMessageValidationResult = yield call(POSTToggleMessagePrice, message.messageNumber, message.concurrencyToken);
      if (response.isValid) {
        yield put(getMessage(message.messageNumber));
      }
    } catch {
      console.log("Toggle message price failed");
    }
  }
}

function* watchToggleSearchable() {
  while (true) {
    yield take(REQUEST_TOGGLE_SEARCHABLE);
    const { messages } = yield select();
    const { message } = messages.current;

    try {
      const response: IMessageValidationResult = yield call(POSTToggleSearchable, message.messageNumber, message.concurrencyToken);
      if (response.isValid) {
        yield put(getMessage(message.messageNumber));
      }
    } catch {
      console.log("Toggle message searchable failed");
    }
  }
}

export const messageSagas = [
  fork(watchChangeState),
  fork(watchRequestMessage),
  fork(watchDeleteDraft),
  fork(watchReturnToOwner),
  fork(watchUndoReturnToOwner),
  fork(watchUpdateInternalComment),
  fork(watchCorrect),
  fork(watchCopy),
  fork(watchToggleMessagePrice),
  fork(watchToggleSearchable),
  fork(watchToggleCorrectionType),
];

// REDUCER
export interface IMessagesState {
  deletedMessages: string[];
  current: IMessageQueryResult | null;
  currentStateIsValid: boolean;
  currentSetStateFeedBack?: IMessageValidationResult | undefined;
  copyCreatedWithMessageNumber?: string;
}

const initialState: IMessagesState = {
  deletedMessages: [],
  current: null,
  currentStateIsValid: true,
};

export default (state = initialState, action: any): IMessagesState => {
  switch (action.type) {
    case RECEIVE_DELETE_DRAFT:
      return { ...state, deletedMessages: action.payload };
    case REQUEST_GET_MESSAGE:
      return { ...state, current: null, currentSetStateFeedBack: undefined, currentStateIsValid: true };
    case EditMessageActionsTypes.RECEIVE_EDIT_MESSAGE:
      return { ...state, currentStateIsValid: true, currentSetStateFeedBack: undefined };
    case SET_STATE_VALIDITY:
      return { ...state, currentStateIsValid: action.payload.isValid, currentSetStateFeedBack: action.payload.currentSetStateFeedBack };
    case RECEIVE_GET_MESSAGE:
      return { ...state, current: action.payload };
    case RECEIVE_COPY_MESSAGE:
      return { ...state, copyCreatedWithMessageNumber: action.payload };
    case RECEIVE_UPDATE_INTERNAL_COMMENT:
      return { ...state, current: { ...state.current!, message: { ...state.current!.message, internalComment: action.payload } } };
    default:
      return state;
  }
};
