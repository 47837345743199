import { createAction, IActionWithPayload } from "../actionHelper";
import { IJurisdictionModel } from "./JurisdictionModel";
import { apiGET } from "../../api";
import { fork, call, put } from "redux-saga/effects";
import { AppState } from "../../reducers";

const RECEIVE_JURISDICTIONS = "RECEIVE_JURISDICTIONS";

export interface IJurisdictionReducerState {
  jurisdictions: IJurisdictionModel[];
}

const GETJurisdictions = () => apiGET<IJurisdictionModel[]>("api/jurisdiction");

const receiveJuristictions = (jurisdictions: IJurisdictionModel[]) => createAction(RECEIVE_JURISDICTIONS, jurisdictions);

const getJurisdictions = () => async (dispatch: any, getState: () => AppState) => {
  const { jurisdictions } = getState();
  if (jurisdictions.jurisdictions.length === 0) {
    const jurisdictionResult = await GETJurisdictions();
    dispatch(receiveJuristictions(jurisdictionResult));
  }
};

export interface IJurisdictionActions {
  getJurisdictions: () => void;
}

export const JurisdictionActions: IJurisdictionActions = {
  getJurisdictions,
};

const defaultState: IJurisdictionReducerState = {
  jurisdictions: [],
};

// SAGAS
function* initJurisdictions() {
  const jurisdictions: IJurisdictionModel[] = yield call(GETJurisdictions);
  yield put(receiveJuristictions(jurisdictions));
}

export const jurisdictionsSagas = [fork(initJurisdictions)];

export default (state: IJurisdictionReducerState = defaultState, action: IActionWithPayload<string, IJurisdictionModel[]>): IJurisdictionReducerState => {
  switch (action.type) {
    case RECEIVE_JURISDICTIONS:
      return {
        ...state,
        jurisdictions: action.payload,
      };

    default:
      return state;
  }
};
