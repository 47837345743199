import { createAction } from "../actionHelper";
import { apiGET } from "../../api";
import { ISigningCertificateInfo, IPdfSigningCertificates } from "./SigningCertificateModel";

export interface ISigningCertificatesState {
  signingCertificates?: IPdfSigningCertificates;
}

export enum SigningCertificateAction {
  RECEIVE_SIGNINGCERTIFICATEATIONS = "RECEIVE_SIGNINGCERTIFICATE",
}

export interface ISigningCertificateActions {
  getSigningCertificates: () => Promise<void>;
}

const receiveSigningCertificates = (signingCertificates: IPdfSigningCertificates) =>
  createAction(SigningCertificateAction.RECEIVE_SIGNINGCERTIFICATEATIONS, signingCertificates);

const getSigningCertificates = () => async (dispatch: any, getState: any) => {
  const signingCertificates = getState().signingCertificates;

  if (!!signingCertificates.pdfSigningCertificates) {
    return;
  }

  const certs = await apiGET<IPdfSigningCertificates>("api/publicationfile/signingcertificates");
  dispatch(receiveSigningCertificates(certs));
};

export const SigningCertificateActions = { getSigningCertificates };

export default (state: ISigningCertificatesState = {}, action: any): ISigningCertificatesState => {
  switch (action.type) {
    case SigningCertificateAction.RECEIVE_SIGNINGCERTIFICATEATIONS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
