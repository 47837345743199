import * as React from "react";

interface IEmailValidationForm {
  email: string;
  isEmailActionCompleted: boolean;
  isEmailActionFailed: boolean;
  isEmailValid: boolean;
  successMessage: string;
  errorMessage: string;
  submitButtonText: string;
  emailInputPlaceholderText: string;
  isReadonly?: boolean;
  onFormSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onEmailChange?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}

export const EmailValidationForm = (props: IEmailValidationForm) => {
  const {
    email,
    isEmailValid,
    isEmailActionFailed,
    isEmailActionCompleted,
    errorMessage,
    successMessage,
    submitButtonText,
    emailInputPlaceholderText,
    onEmailChange,
    onFormSubmit,
    isReadonly,
  } = props;
  const isInvalid = isEmailValid && !isEmailActionFailed ? "" : "is-invalid";

  return (
    <React.Fragment>
      {!isEmailActionCompleted && (
        <form onSubmit={onFormSubmit} className="needs-validation" noValidate={true}>
          <div className="input-group">
            <input
              readOnly={!!isReadonly}
              required={true}
              type="email"
              className={`form-control ${isInvalid}`}
              placeholder={emailInputPlaceholderText}
              aria-label={emailInputPlaceholderText}
              value={email}
              onChange={onEmailChange}
            />
            {(!isEmailValid || isEmailActionFailed) && <div className="invalid-tooltip">{errorMessage}</div>}
            <button className="btn btn-secondary" type="submit">
              {submitButtonText}
            </button>
          </div>
        </form>
      )}
      {isEmailActionCompleted && <div>{successMessage}</div>}
    </React.Fragment>
  );
};
