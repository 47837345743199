import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware, { END } from "redux-saga";
import rootReducer from "./reducers";
import sagas from "./sagas";

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer,
    // (window as any).__INITIAL_STATE__,
    compose(
      applyMiddleware(...[thunk, sagaMiddleware]),
      (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f
    )
  );

  (store as any).runSaga = sagaMiddleware.run;
  (store as any).runSaga(sagas);
  (store as any).close = () => store.dispatch(END);

  return store;
}
