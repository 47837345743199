import React from "react";
import { FieldTemplateProps } from "react-jsonschema-form";
import "./CustomFieldTemplate.scss";
import { HelpIcon } from "./Help-icon";
export const CustomFieldTemplate: React.FC<FieldTemplateProps> = props => {
  const { id, classNames, label, help, required, description, errors, children, uiSchema } = props;

  var requiredClassNames = required ? " required" : "";
  if (uiSchema.canbesuppressedbyfieldid) requiredClassNames = " suppressable";
  if (uiSchema.ismandatorycanbesuppressedbyfieldid) requiredClassNames = " required-suppressable";

  if (uiSchema.cansuppressfieldid) requiredClassNames = requiredClassNames + " suppressing";

  if (classNames.indexOf("field-array-of-object") > -1 || classNames.indexOf("field-object") > -1) {
    return (
      <div className={classNames + " custom-field-template"}>
        <div className="field-group-title">
          <span>{label}</span>
          <HelpIcon helpText={help.props.help} id={id} />
        </div>
        {children}
      </div>
    );
  } else if (classNames.indexOf("field-boolean") > -1) {
    return (
      <div className={classNames + " custom-field-template" + requiredClassNames}>
        {description}
        {children}
        <HelpIcon helpText={help.props.help} id={id} />
        {required || uiSchema.ismandatorycanbesuppressedbyfieldid ? (
          <span className="required">
            *<span className="sr-only"> Dette er et påkrævet felt</span>
          </span>
        ) : null}
        {errors}
      </div>
    );
  } else {
    return (
      <div className={classNames + " custom-field-template" + requiredClassNames}>
        <label htmlFor={id}>
          {label}
          {required || uiSchema.ismandatorycanbesuppressedbyfieldid ? (
            <span className="required">
              *<span className="sr-only"> Dette er et påkrævet felt</span>
            </span>
          ) : null}
        </label>
        <HelpIcon helpText={help.props.help} id={id} />
        {description}
        {children}
        {errors}
      </div>
    );
  }
};
