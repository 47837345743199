import { createAction } from "../../reducers/actionHelper";
import { apiGET } from "../../api";
import { IFaqAreaModel } from "./faqModels";

export enum FaqAction {
  RECEIVE_FAQ = "RECEIVE_FAQ",
}

export interface IFaqState {
  faqAreas?: IFaqAreaModel[];
}

const receiveFaq = (faqAreas: IFaqAreaModel) => createAction(FaqAction.RECEIVE_FAQ, faqAreas);

const getFaq = () => async (dispatch: any, getState: any) => {
  const faq = getState().faq;

  if (faq.faqAreas) {
    return;
  }

  dispatch(receiveFaq(await apiGET<IFaqAreaModel>("api/faq")));
};

export const FaqActions = { getFaq };

export default (state: IFaqState = {}, action: any): IFaqState => {
  switch (action.type) {
    case FaqAction.RECEIVE_FAQ: {
      return { ...state, ...{ faqAreas: action.payload } };
    }
    default:
      return state;
  }
};
