import { createAction, ActionsUnion } from "../actionHelper";
import { apiGET } from "../../api";
import { Dispatch } from "redux";
import { IPublicationDates, IPublicationDateModel, IPublicationDateModelDto, IPublicationDate } from "./PublicationDateModel";
import { IRootReducer } from "../../reducers";
import { DateFormats } from "@lexdania/components";
import { parse } from "date-fns";

import { take, put, fork } from "redux-saga/effects";
import { SET_PUBLICATION_ACTION_STARTED } from "../productionCalendar/productionCalendar";

// Action
const RECEIVE_PUBLICATIONDATES = "RECEIVE_PUBLICATIONDATES";
const REQUEST_INVALIDATE_PUBLICATIONDATES_CACHE = "REQUEST_INVALIDATE_PUBLICATIONDATES_CACHE";

export interface IPublicationDatesState {
  invalidationTime?: Date;
  publicationDates: IPublicationDates;
}

const receivePublicationDates = (publicationDateModel: IPublicationDateModel) => createAction(RECEIVE_PUBLICATIONDATES, publicationDateModel);
const invalidateAction = () => createAction(REQUEST_INVALIDATE_PUBLICATIONDATES_CACHE);

export interface IPublicationDatesActions {
  getPublicationDateModel(): void;
}

// Fetch
const getPublicationDateModel = () => async (dispatch: Dispatch<PublicationDatesActions>, getState: () => IRootReducer) => {
  const publicationDateModel = getState().publicationDateModel;
  const { dailyPublications, quarterlyPublications } = publicationDateModel.publicationDates;

  if (dailyPublications.length > 0 && quarterlyPublications.length > 0) {
    return;
  }

  const { invalidationTime, publicationDates } = await apiGET<IPublicationDateModelDto>("api/publication/publicationdates");
  const parseDate = (dates: IPublicationDate[]): IPublicationDate[] => {
    return dates.map<IPublicationDate>(x => {
      return {
        date: x.date,
        parsedDate: parse(x.date, DateFormats.Default, new Date()),
        userNotification: x.userNotification,
      };
    });
  };

  const modelResult: IPublicationDateModel = {
    invalidationTime,
    publicationDates: {
      dailyPublications: parseDate(publicationDates.dailyPublications),
      quarterlyPublications: parseDate(publicationDates.quarterlyPublications),
    },
  };

  dispatch(receivePublicationDates(modelResult));
};

const Actions = { receivePublicationDates, invalidate: invalidateAction };
type PublicationDatesActions = ActionsUnion<typeof Actions>;

export const PublicationDateModelAction = { getPublicationDateModel };

// Reducer
const initialState: IPublicationDatesState = {
  publicationDates: {
    dailyPublications: [],
    quarterlyPublications: [],
  },
};

export default (state = initialState, action: PublicationDatesActions): IPublicationDatesState => {
  switch (action.type) {
    case RECEIVE_PUBLICATIONDATES: {
      return { ...state, ...action.payload };
    }
    case REQUEST_INVALIDATE_PUBLICATIONDATES_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

// Saga(s)
function* watchPublicationPrepared() {
  while (true) {
    // When a publication has been prepared og reopened, invalidate the publication dates cache
    const { payload: isStarted } = yield take(SET_PUBLICATION_ACTION_STARTED);
    if (isStarted === false) {
      yield put(invalidateAction());
    }
  }
}

export const publicationDateSagas = [fork(watchPublicationPrepared)];
