import * as React from "react";
import { WidgetProps } from "react-jsonschema-form";
import { IAddress } from "../../../containers/message/models/IAddress";
import AddressPicker from "../address-picker/AddressPicker";
import AutoCompleteFieldService from "./AutoCompleteFieldService";

interface IStreetNameWidgetState {
  address: IAddress;
  notifySubscribers: boolean;
}

class StreetNameWidget extends React.Component<WidgetProps, IStreetNameWidgetState> {
  constructor(props: WidgetProps) {
    super(props);

    this.state = {
      address: this.props.value,
      notifySubscribers: false,
    };
  }

  public componentDidUpdate(prevProps: WidgetProps) {
    // If prepared for address notifications and form data has changed (due to an onChange),
    // notify autocomplete fields and unprepare for notifications again
    if (this.state.notifySubscribers) {
      const { formContext } = this.props;
      if (
        formContext.onNotifyAutoCompleteFields &&
        formContext.changeEvent &&
        formContext.changeEvent.formData &&
        this.props.formContext.changeEvent.formData !== prevProps.formContext.changeEvent.formData
      ) {
        this.setState(
          { notifySubscribers: false }, // unprepare
          () => {
            // Notify other fields (at this point formData contains data from the previous called onChange([vejnavn]))
            const currentAddress = this.state.address;
            const acfs = new AutoCompleteFieldService(this.props.id, formContext.changeEvent, this.props.schema);
            acfs.setFieldValue("addressNameField", currentAddress.vejnavn ? currentAddress.vejnavn : undefined);
            acfs.setFieldValue("addressNumberField", currentAddress.husnr ? currentAddress.husnr : undefined);
            acfs.setFieldValue("addressFloorField", currentAddress.etage ? currentAddress.etage : undefined);
            acfs.setFieldValue("addressDoorField", currentAddress.dør ? currentAddress.dør : undefined);
            acfs.setFieldValue("addressZipField", currentAddress.postnr ? currentAddress.postnr : undefined);
            acfs.setFieldValue("addressCityField", currentAddress.postnrnavn ? currentAddress.postnrnavn : undefined);
            acfs.setFieldValue("addressVillageField", currentAddress.supplerendebynavn ? currentAddress.supplerendebynavn : undefined);
            formContext.onNotifyAutoCompleteFields(formContext.changeEvent);
          }
        );
      }
    }
  }

  public render() {
    return (
      <div className="address-widget">
        <AddressPicker
          id={this.props.id}
          onAddressSelected={address => {
            // Prepare for address field notifications and when prepared, change value (will cause props to change)
            this.setState({ notifySubscribers: true, address: address }, () => {
              this.props.onChange(address.vejnavn);
            });
          }}
          onChange={this.props.onChange} // Notify forms on address text change
          value={this.state.address}
          required={this.props.required}
          onAutocompleteChange={streetName => {
            this.setState({
              address: {
                ...this.state.address,
                vejnavn: streetName,
              },
            });
          }}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

export default StreetNameWidget;
