import { createAction } from "../actionHelper";
import { apiGET } from "../../api";

export interface IPublicationState {
  latestPublication?: ILatestPublication;
  publicationDateFetchTime?: Date;
  sectionCounts?: object;
  topMessageTypeCounts?: Array<ITopMessageTypeCounts>;
}

export enum PublicationAction {
  RECEIVE_PUBLICATION = "RECEIVE_PUBLICATION",
}

interface ILatestPublication {
  date: Date;
  fileId: string;
}

export interface ITopMessageTypeCounts {
  messageTypeId: number;
  count: number;
  sectionId: number;
  publicKey: string;
  name: string;
}

interface IPublicationModel extends ILatestPublication {
  sectionCounts: object;
  topMessageTypeCounts: ITopMessageTypeCounts;
}

interface IPublicationDateUpdate {
  latestPublication: ILatestPublication;
  publicationDateFetchTime: Date;
  sectionCounts: object;
  topMessageTypeCounts: ITopMessageTypeCounts;
}

const receivePublicationDate = (publicationUpdate: IPublicationDateUpdate) => createAction(PublicationAction.RECEIVE_PUBLICATION, publicationUpdate);

const getLatestPublication = () => async (dispatch: any, getState: any) => {
  const { latestPublication, publicationDateFetchTime } = getState().publications;

  if (latestPublication) {
    const expired = publicationDateFetchTime ? Math.abs(Date.now() - publicationDateFetchTime) / 36e5 > 4 : false;

    // API returns empty string if no publication date is found. Handle this as a valid date
    if ((latestPublication.Date || latestPublication.Date === "") && !expired) {
      return;
    }
  }

  const { date, fileId, sectionCounts, topMessageTypeCounts } = await apiGET<IPublicationModel>("api/Publication/GetLatestPublication");
  dispatch(receivePublicationDate({ latestPublication: { date, fileId }, publicationDateFetchTime: new Date(), sectionCounts, topMessageTypeCounts }));
};

export interface IPublicationActions {
  getLatestPublication(): void;
}

export const PublicationActions: IPublicationActions = {
  getLatestPublication,
};

const initialState: IPublicationState = {};

export default (state = initialState, action: any): IPublicationState => {
  switch (action.type) {
    case PublicationAction.RECEIVE_PUBLICATION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
