import * as React from "react";
import { TreeNode, INode } from "./TreeNode";
import "./tree.scss";

interface ITreeProps {
  id: string;
  nodes: INode[];
  onNodeToggled: (node: INode) => void;
  onNodeSelected: (selectedNode: INode) => void;
}

export class Tree extends React.Component<ITreeProps, {}> {
  constructor(props: ITreeProps) {
    super(props);
  }

  public render() {
    const { id, nodes, onNodeToggled, onNodeSelected } = this.props;

    return (
      <ul id={id} className="NodeTree">
        {nodes.map((node: INode, i: number) => (
          <TreeNode key={`${id}_${node.id}`} nodeId={`${id}_${node.id}`} index={i} node={node} onNodeToggled={onNodeToggled} onNodeSelected={onNodeSelected} />
        ))}
      </ul>
    );
  }
}
