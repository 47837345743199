import { ErrorOnNonAcceptedImageTypesText } from "./FileWithThumbnailWidget";

export function GetSchemaForField(schema: any, field: string): any {
  const fieldNames = getFieldNames(field);

  if (fieldNames.length < 3) {
    return {};
  }

  const isRepeatedFieldGroup: boolean = schema.properties[fieldNames[1]] && schema.properties[fieldNames[1]].type === "array";

  if (isRepeatedFieldGroup && schema.properties[fieldNames[1]] && schema.properties[fieldNames[1]].items) {
    const item = schema.properties[fieldNames[1]].items.properties[fieldNames[2]];
    if (item.type === "array") {
      return item.items;
    }
    return item;
  } else if (schema.properties && schema.properties[fieldNames[1]] && schema.properties[fieldNames[1]].properties) {
    const item = schema.properties[fieldNames[1]].properties[fieldNames[2]];
    if (item.type === "array") {
      return item.items;
    }
    return item;
  }
  return {};
}

export function GetSchemaForFieldGroup(schema: any, group: string): any {
  const groupId = group.substring(1);
  if (schema.properties[groupId] && schema.properties[groupId].items) {
    return schema.properties[groupId].items;
  }
  return {};
}

function getFieldNames(field: string): string[] {
  // For repeated groups name may by e.g. "fieldgroupId86[0]"
  const split = field.split(".");
  return split.map(fieldEntry => {
    const bracketIndex = fieldEntry.indexOf("[");
    if (bracketIndex === -1) {
      return fieldEntry;
    } else {
      return fieldEntry.substring(0, bracketIndex);
    }
  });
}

export function TransformErrors(jsonSchema: object, uiSchema: object, errors: any) {
  return errors.map((error: any) => {
    const fieldschema = GetSchemaForField(jsonSchema, error.property);
    const { type, format, property, params } = error;

    const buildError = (message: string, stack: string) => ({ ...error, message, stack });
    const buildStack = (msg: string) => `'${fieldschema.title}' ${msg}`;

    // Handle Number
    if (error.name === "number" || type === "number" || type === "integer") {
      return buildError("Feltet skal udfyldes med et nummer", buildStack("skal udfyldes med et nummer"));
    }

    switch (error.name) {
      case "required":
        return buildError("Feltet er obligatorisk og skal derfor udfyldes", buildStack("er obligatorisk og skal derfor udfyldes"));
      case "format":
        if (format !== "date") {
          return;
        }
        return buildError("Feltet skal være i datoformatet dd.mm.åååå", buildStack("skal være i datoformatet dd.mm.åååå"));
      case "minItems":
        const minMsg = buildStack(`skal minimum indeholde ${params.limit} `);
        return buildError(minMsg, minMsg);
      case "maxItems":
        const maxMsg = buildStack(`kan ikke indeholde mere end ${params.limit} ${params.limit === 1 ? "værdi" : "værdier"}`);
        return buildError(maxMsg, maxMsg);
      case "enum": // Enum is used for mandatory suppressible fields. If used for other rules, find another solution for errormessages.
        return buildError("Feltet er obligatorisk og skal derfor udfyldes", buildStack("er obligatorisk og skal derfor udfyldes"));
      case "type":
        // This is a hack needed to remove "should be string" error message on empty repeatable fields.
        // Extracting fieldgroupId and fieldId from the property field
        const match = error.property.match(/\.(fieldgroupId\d+)(?:\[\d+\])?\.(fieldId\d+)(?:\[\d+\])?/);
        if (match && "properties" in jsonSchema) {
          const [_, fieldgroupId, fieldId] = match;
          const fieldGroup = (jsonSchema as { properties?: Record<string, any> }).properties?.[fieldgroupId];
          const properties = fieldGroup?.properties ?? fieldGroup?.items?.properties;
          const field = properties[fieldId];

          if (field.type === "array") {
            return buildError("Feltet skal udfyldes", buildStack("skal udfyldes"));
          }
        }
        break;
      case "pattern":
        const widgetName = getWidgetNameForField(uiSchema, property);
        if (widgetName === "fileWithThumbnailWidget") {
          return buildError(ErrorOnNonAcceptedImageTypesText, `'${widgetName}': ${ErrorOnNonAcceptedImageTypesText}`);
        }
        break;
    }

    return GetCustomErrorMessage(jsonSchema, error);
  });
}

export function GetCustomErrorMessage(schema: any, error: any) {
  const fieldschema = GetSchemaForField(schema, error.property);
  if (fieldschema.validationMessage) {
    const message = fieldschema.validationMessage;
    const stack = "'" + fieldschema.title + "': " + fieldschema.validationMessage;
    return { ...error, message: message, stack: stack };
  }
  return { ...error, message: error.message, stack: error.stack };
}

export function getWidgetNameForField(uiSchema: any, field: string): string | undefined {
  const fieldnames = field.split(".");

  if (fieldnames.length < 3) {
    return undefined;
  }

  const fieldGroupObject = uiSchema[fieldnames[1]];
  if (!fieldGroupObject) {
    return undefined;
  }

  const fieldObject = uiSchema[fieldnames[1]][fieldnames[2]];
  if (!fieldObject) {
    return undefined;
  }

  return uiSchema[fieldnames[1]][fieldnames[2]]["ui:widget"];
}
