import * as React from "react";
import { Redirect, RouteComponentProps, RouteProps, Route } from "react-router-dom";
import { HasClaimPrivilege } from "../../services/ClaimService";
import GlobalErrorForbidden from "./error/Forbidden";
import { connect } from "react-redux";
import { IUserState } from "../../reducers/user";
import { Loading } from "../inner-components";
import { apiPOST } from "../../api";

type RouteComponent = React.FunctionComponent<RouteComponentProps<{}>> | React.ComponentClass<any>;

interface ICustRouteProps extends RouteProps {
  requiredPrivilege?: string;
  user: IUserState;
}

const AuthenticatedRoute: React.FC<ICustRouteProps> = ({ component, user, requiredPrivilege, ...rest }) => {
  const renderFn = (Component?: RouteComponent) => (props: RouteComponentProps) => {
    if (!Component) {
      return null;
    }

    const { location } = props;
    const { search, pathname } = location!;

    if (user.userLoading) {
      return <Loading />;
    }

    const isAutoLoggedOut =
      user.refreshTokenInformation &&
      (user.refreshTokenInformation.hasBeenAutomaticallyLoggedOut ||
        user.refreshTokenInformation.hasBeenLoggedOutFromElsewhere ||
        user.refreshTokenInformation.hasBeenDeletedAndLoggedOut);

    if (!user.profile && !isAutoLoggedOut) {
      const path = props.location !== undefined ? "?returnUrl=" + encodeURIComponent(pathname + search) : "";
      return <Redirect to={`/login${path}`} />;
    }

    if (requiredPrivilege !== undefined && !HasClaimPrivilege(requiredPrivilege) && !isAutoLoggedOut) {
      apiPOST("/api/auditlog", {
        messageTemplate: `Missing ClaimPrivilege access to '{0}' for userid:{1} name:{2}`,
        messagePropertyValues: [pathname, user!.profile!.id, user!.profile!.name],
      });
      return <GlobalErrorForbidden />;
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={renderFn(component)} />;
};

export default connect(({ user }: any) => ({ user }))(AuthenticatedRoute);
