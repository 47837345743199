// JWT Token
export interface IStatstidendeJwtToken {
  aud: string;
  exp: number;
  nbf: number;
  iss: string;
  urnFirstName: string;
  urnLastName: string;
  urnAge: string;
  dkstatstidendeattributeUserProfileId: number;
  dkstatstidendeattributePrivileges: string[];
  dkgovsamlattributeAssuranceLevel: string;
  dkgovsamlattributePidNumberIdentifier: string;
  dkstatstidendename: string;
}

export interface IUserProfileEnvelope {
  isDisabled: boolean;
  isComplete?: boolean;
  profile?: IUserProfile;
}

export interface IUserUpdatedResponse {
  profile?: IUserProfile;
  isEmailUnique: boolean;
  isValid: boolean;
}

export interface IUserProfile {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  coName: string;
  street: string;
  houseNumber: string;
  floor: string;
  sideOrDoor: string;
  city: string;
  zipCode: string;
  poBox: string;
  fax: string;
  country: string;
  ean: string;
  emailValidationStatus: number;
  cvrNumber?: string;
  teams: ITeamModel[];
}

export interface ITeamModel {
  title: string;
  id: string;
}

export enum EmailValidationStatusType {
  Unknown,
  Submitted,
  Approved,
}
