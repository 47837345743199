import { take, call, fork, select, put } from "redux-saga/effects";
import { delay } from "redux-saga";
import { createAction } from "../actionHelper";
import { apiGET, apiPUT, apiPOST, apiDELETE } from "../../api";
import { AxiosService } from "../../services/AxiosService";
import Storage from "./Storage";
import { IUserProfile, IUserProfileEnvelope, IUserUpdatedResponse, IStatstidendeJwtToken, EmailValidationStatusType } from "./types";
import * as ConfigService from "../../services/ConfigService";
import { differenceInSeconds, subSeconds, subMinutes } from "date-fns";
import { IResult } from "../../components/viewmodels/Result";

// Action Constants
const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER";
const REQUEST_CURRENT_USER = "REQUEST_CURRENT_USER";
const REQUEST_FORCE_GET_CURRENT_USER = "REQUEST_FORCE_GET_CURRENT_USER";
const REQUEST_UPDATE_CURRENT_USER = "REQUEST_UPDATE_CURRENT_USER";
const RECEIVE_UPDATE_CURRENT_USER = "RECEIVE_UPDATE_CURRENT_USER";
const REQUEST_SEND_EMAIL_VALIDATION = "REQUEST_SEND_EMAIL_VALIDATION";
const REQUEST_CONFIRM_EMAIL_VALIDATION = "REQUEST_CONFIRM_EMAIL_VALIDATION";
const RECEIVE_CONFIRM_EMAIL_VALIDATION = "RECEIVE_CONFIRM_EMAIL_VALIDATION";
const RESET_ERRORS = "RESET_ERRORS";
const REQUEST_LOGIN = "REQUEST_LOGIN";
const REQUEST_LOGOUT = "REQUEST_LOGOUT";
const REQUEST_USER_LOGOUT = "REQUEST_USER_LOGOUT";
const RECEIVE_LOGOUT = "RECEIVE_LOGOUT";
const RECEIVE_LOGIN = "RECEIVE_LOGIN";
const REQUEST_TOKEN_DIALOG = "REQUEST_TOKEN_DIALOG";
const REQUEST_REFRESH_TOKEN = "REQUEST_REFRESH_TOKEN";
const USER_INACTIVE = "USER_INACTIVE";
const USER_ACTIVE = "USER_ACTIVE";
const REQUEST_USER_DELETION = "REQUEST_USER_DELETION";
const DISMISS_SESSION_EXPIRED_DIALOG = "DISMISS_SESSION_EXPIRED_DIALOG";
const USER_DELETED_LOGGED_OUT = "USER_DELETED_LOGGED_OUT";
const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export interface IUserState extends IUserProfileEnvelope {
  isValid: boolean;
  userLoading: boolean;
  isEmailUnique: boolean;
  profile?: IUserProfile;
  token?: IStatstidendeJwtToken;
  refreshTokenInformation?: IRefreshTokenInformation;
  isActive: boolean;
  emailValidationResult?: IResult;
}

export interface IUserUpdatedResponseWithProfile extends IUserUpdatedResponse {
  profile: IUserProfile;
}

// Actions
const receiveCurrentUser = (userEnvelope: IUserProfileEnvelope) => createAction(RECEIVE_CURRENT_USER, userEnvelope);
const receiveCurrentUserUpdated = (response: IUserUpdatedResponseWithProfile) => createAction(RECEIVE_UPDATE_CURRENT_USER, response);
const resetErrors = () => createAction(RESET_ERRORS);
const getCurrentUser = () => createAction(REQUEST_CURRENT_USER);
const forceGetCurrentUser = () => createAction(REQUEST_FORCE_GET_CURRENT_USER);
const updateCurrentUser = (profile: IUserProfile, oldEmail: string) => createAction(REQUEST_UPDATE_CURRENT_USER, { profile: profile, oldEmail: oldEmail });
const sendEmailValidation = (status: EmailValidationStatusType) => createAction(REQUEST_SEND_EMAIL_VALIDATION, status);
const confirmEmailValidation = (email: string, token: string) => createAction(REQUEST_CONFIRM_EMAIL_VALIDATION, { email: email, token: token });
const receiveConfirmEmailValidation = (result?: IResult) => createAction(RECEIVE_CONFIRM_EMAIL_VALIDATION, result);
const logOut = (info?: IRefreshTokenInformation) => createAction(REQUEST_LOGOUT, info);
const userLogOut = () => createAction(REQUEST_USER_LOGOUT);
const logIn = (token: string) => createAction(REQUEST_LOGIN, token);
const receiveLogin = (token: IStatstidendeJwtToken) => createAction(RECEIVE_LOGIN, token);
const receiveLogout = (info?: IRefreshTokenInformation) => createAction(RECEIVE_LOGOUT, info);
const requestTokenDialog = (info: IRefreshTokenInformation) => createAction(REQUEST_TOKEN_DIALOG, info);
const requestRefreshToken = () => createAction(REQUEST_REFRESH_TOKEN);
const userActive = () => createAction(USER_ACTIVE);
const userInactive = () => createAction(USER_INACTIVE);
const requestUserDeletion = () => createAction(REQUEST_USER_DELETION);
const userDeletedAndLoggedOut = () => createAction(USER_DELETED_LOGGED_OUT);
const dismissSessionExpiredDialog = () => createAction(DISMISS_SESSION_EXPIRED_DIALOG);
const userLoggedOut = () => createAction(USER_LOGGED_OUT);

// Action Exports
export interface IUserActions {
  updateCurrentUser: (profile: IUserProfile, oldEmail: string) => void;
  sendEmailValidation: (status: EmailValidationStatusType) => void;
  confirmEmailValidation: (email: string, token: string) => void;
  resetErrors: () => void;
  getCurrentUser: () => void;
  logIn: (token: string) => void;
  userLogOut: () => void;
  requestRefreshToken: () => void;
  userActive: () => void;
  userInactive: () => void;
  requestUserDeletion: () => void;
  dismissSessionExpiredDialog: () => void;
  userDeletedAndLoggedOut: () => void;
  userLoggedOut: () => void;
}

export const UserActions: IUserActions = {
  updateCurrentUser,
  sendEmailValidation,
  confirmEmailValidation,
  userLogOut,
  logIn,
  resetErrors,
  getCurrentUser,
  requestRefreshToken,
  userActive,
  userInactive,
  requestUserDeletion,
  dismissSessionExpiredDialog,
  userDeletedAndLoggedOut,
  userLoggedOut,
};

// API Calls
const fetchCurrent = () => apiGET<IUserProfileEnvelope>("api/user/GetMyUserProfile");
const updateCurrent = (profile: IUserProfile) => apiPUT<IUserUpdatedResponse>("api/user/UpdateMyUserProfile", profile);
const deleteCurrentProfile = () => apiDELETE<any>("api/user/DeleteMyUserProfile");
const sendEmailValidationCall = () => apiPOST<any>("api/user/SendEmailValidation");
const confirmEmailValidationCall = (email: string, token: string) =>
  apiPOST<IResult>(`/api/user/ConfirmEmailValidation`, {
    email: email,
    token: token,
  });

// Logout is only to get auditlogged
const postLogout = () => apiPOST("api/user/logout", "");

const refreshToken = () => apiGET<string>("api/auth/RefreshToken");

let jwtIsPresent = !!Storage.GetValidToken();

// Sagas
function* watchRequestCurrentUser() {
  // Saga never stops. Once done, it restarts and listens again
  while (true) {
    // Wait until someone dispatches REQUEST_CURRENT_USER
    const action: { type: any; payload: any } = yield take(REQUEST_CURRENT_USER);
    // We use select() to grab the current user we have in our state.
    const { user } = yield select();
    // If profile is falsy we will fetch current user from server
    if (!user.profile || action.payload.force) {
      // create no operation (noop) function to pass as callback to setupAxios
      const noop = () => {
        return;
      };
      // Setup Axios Headers since saga runs before layout
      AxiosService.setupAxios(noop);
      try {
        const envelope: IUserProfileEnvelope = yield call(fetchCurrent);
        yield put(receiveCurrentUser(envelope));

        // Add JWT Token to store
        yield put(receiveLogin(Storage.GetValidToken()!));
      } catch (error) {
        console.log("Could not fetch current user");
      }
    }
  }
}

function* watchForceGetCurrentUser() {
  // Saga never stops. Once done, it restarts and listens again
  while (true) {
    // Wait until someone dispatches REQUEST_FORCE_CURRENT_USER
    yield take(REQUEST_FORCE_GET_CURRENT_USER);
    // create no operation (noop) function to pass as callback to setupAxios
    const noop = () => {
      return;
    };
    // Setup Axios Headers since saga runs before layout
    AxiosService.setupAxios(noop);
    try {
      const envelope: IUserProfileEnvelope = yield call(fetchCurrent);
      yield put(receiveCurrentUser(envelope));

      // Add JWT Token to store
      yield put(receiveLogin(Storage.GetValidToken()!));
    } catch (error) {
      console.log("Could not fetch current user");
    }
  }
}

function* watchLogin() {
  while (true) {
    yield take(REQUEST_LOGIN);
    yield put(getCurrentUser());
    jwtIsPresent = !!Storage.GetValidToken();
  }
}

function* watchLogout() {
  while (true) {
    // Wait for logOut TODO test
    const action: { type: any; payload: any } = yield take(REQUEST_LOGOUT);

    Storage.JWTRemove();

    yield put(receiveLogout(action.payload));
    jwtIsPresent = !!Storage.GetValidToken();
  }
}

function* watchUserLogout() {
  while (true) {
    // Wait for user logOut
    yield take(REQUEST_USER_LOGOUT);

    if (Storage.HasValidToken()) {
      // Audit log logout
      yield call(postLogout);
    }

    ConfigService.GetConfig().then(conf => {
      const u = conf.loginSiteUrl + "/logout.ashx";
      window.location.href = u;
    });
  }
}

function* watchUserRefreshToken() {
  while (true) {
    // Wait for user refreshing token
    yield take(REQUEST_REFRESH_TOKEN);
    const newToken: string = yield call(refreshToken);
    Storage.SetJWT(newToken);
  }
}

function* watchUpdateCurrentUser() {
  while (true) {
    const { payload: payload } = yield take(REQUEST_UPDATE_CURRENT_USER);
    const profile = payload.profile as IUserProfile;

    if (profile.email !== payload.oldEmail) {
      profile.emailValidationStatus = EmailValidationStatusType.Unknown;
    }

    const response: IUserUpdatedResponse = yield call(updateCurrent, profile);
    if (profile.email !== payload.oldEmail && response.isValid) {
      yield put(forceGetCurrentUser());
    } else {
      yield put(receiveCurrentUserUpdated({ ...response, profile, ...{ isComplete: response.isValid } }));
    }
  }
}

function* watchSendEmailValidation() {
  while (true) {
    yield take(REQUEST_SEND_EMAIL_VALIDATION);
    yield call(sendEmailValidationCall);
  }
}

function* watchConfirmEmailValidation() {
  while (true) {
    const { payload: payload } = yield take(REQUEST_CONFIRM_EMAIL_VALIDATION);
    const result: IResult = yield call(confirmEmailValidationCall, payload.email, payload.token);
    yield put(receiveConfirmEmailValidation(result));

    if (result.isSuccess && Storage.HasValidToken()) {
      yield put(forceGetCurrentUser());
    }
  }
}

function* watchUserDeleted() {
  while (true) {
    yield take(REQUEST_USER_DELETION);
    yield call(deleteCurrentProfile);

    localStorage.setItem("isDeleted", "true");

    if (Storage.HasValidToken()) {
      yield call(postLogout);
    }

    ConfigService.GetConfig().then(conf => {
      const u = conf.loginSiteUrl + "/logout.ashx";
      window.location.href = u;
    });
  }
}

function* watchToken() {
  while (true) {
    yield call(delay, 1000);

    const [token, tokenString] = Storage.JWTGetTokenAndTokenString() || [undefined, undefined];
    const user: IUserState = yield select((x: any) => x.user);
    if (jwtIsPresent && !token) {
      // The token disappeared
      yield put(
        logOut({
          hasBeenAutomaticallyLoggedOut: false,
          hasBeenLoggedOutFromElsewhere: true,
          isAboutToExpire: false,
          hasBeenDeletedAndLoggedOut: false,
          secondsToLogout: 0,
          userLoggedOut: false,
        })
      );
    } else if (jwtIsPresent && token && Storage.isJWTExpired(token)) {
      // The token has expired
      yield put(
        logOut({
          hasBeenAutomaticallyLoggedOut: true,
          hasBeenLoggedOutFromElsewhere: false,
          isAboutToExpire: false,
          hasBeenDeletedAndLoggedOut: false,
          secondsToLogout: 0,
          userLoggedOut: false,
        })
      );
    } else if (!jwtIsPresent && token && !Storage.isJWTExpired(token)) {
      // The token has appeared
      yield put(logIn(tokenString!));
    } else if (jwtIsPresent && token && getNotifyTokenExpiryTime(token) < new Date()) {
      // The token is about to expire and the user is inactive - display dialog if user is inactive
      const secs = differenceInSeconds(new Date(token.exp * 1000), new Date());
      const info = {
        hasBeenAutomaticallyLoggedOut: false,
        isAboutToExpire: true,
        hasBeenLoggedOutFromElsewhere: false,
        hasBeenDeletedAndLoggedOut: false,
        userLoggedOut: false,
        secondsToLogout: secs,
      };
      yield put(requestTokenDialog(info));
    } else if (jwtIsPresent && token && user.isActive && getAutoRefreshTokenTime(token) < new Date()) {
      // Halfways to token expiration - user is active - automatically refresh token
      if (!user.refreshTokenInformation || !user.refreshTokenInformation.isAboutToExpire) {
        yield put(requestRefreshToken());
      }
    } else if (jwtIsPresent && token && !Storage.isJWTExpired(token) && user.refreshTokenInformation && user.refreshTokenInformation.isAboutToExpire) {
      // If displaying dialog and token has been refreshed, clear dialog
      const info = {
        isAboutToExpire: false,
        hasBeenAutomaticallyLoggedOut: false,
        hasBeenLoggedOutFromElsewhere: false,
        hasBeenDeletedAndLoggedOut: false,
        secondsToLogout: 0,
        userLoggedOut: false,
      };
      yield put(requestTokenDialog(info));
    }
  }
}

const getAutoRefreshTokenTime = (token: IStatstidendeJwtToken) => {
  // Calculates the time halfway through token
  const expiry = new Date(token.exp * 1000);
  const diff = differenceInSeconds(expiry, new Date(token.nbf * 1000));
  return subSeconds(expiry, diff / 2);
};

const getNotifyTokenExpiryTime = (token: IStatstidendeJwtToken) => {
  // Calculates first to come time halfways through token or 3 minutes before expiry
  const autoRefreshTime = getAutoRefreshTokenTime(token);
  const notificationTime = subMinutes(new Date(token.exp * 1000), 3);

  return autoRefreshTime > notificationTime ? autoRefreshTime : notificationTime;
};

function* fetchUserIfValidToken() {
  // Notice that there is no while(true) loop. We only want to run this once
  // Get valid non expired token from store
  if (Storage.HasValidToken()) {
    // Dispatch getCurrentUser which will trigger watchRequestCurrentUser
    yield put(getCurrentUser());
  }
}

export const userSagas = [
  fork(watchLogin),
  fork(watchLogout),
  fork(watchUserLogout),
  fork(watchRequestCurrentUser),
  fork(watchUpdateCurrentUser),
  fork(fetchUserIfValidToken),
  fork(watchToken),
  fork(watchUserRefreshToken),
  fork(watchSendEmailValidation),
  fork(watchConfirmEmailValidation),
  fork(watchForceGetCurrentUser),
  fork(watchUserDeleted),
];

// Reducer
const initialState: IUserState = {
  isDisabled: false,
  userLoading: false,
  isValid: true,
  isEmailUnique: true,
  token: undefined,
  profile: undefined,
  refreshTokenInformation: undefined,
  isActive: true,
  emailValidationResult: undefined,
};

export interface IRefreshTokenInformation {
  isAboutToExpire: boolean;
  hasBeenAutomaticallyLoggedOut: boolean;
  hasBeenLoggedOutFromElsewhere: boolean;
  hasBeenDeletedAndLoggedOut: boolean;
  secondsToLogout: number;
  userLoggedOut: boolean;
}

export default (state: IUserState = initialState, action: any): IUserState => {
  switch (action.type) {
    case REQUEST_CURRENT_USER:
      return { ...state, ...{ userLoading: true } };
    case RECEIVE_CURRENT_USER:
      return { ...state, ...action.payload, ...{ userLoading: false, isEmailUnique: true } };
    case RESET_ERRORS:
      return { ...state, ...{ isEmailUnique: true } };
    case RECEIVE_LOGIN:
      return { ...state, ...{ token: action.payload } };
    case RECEIVE_LOGOUT:
      return { ...initialState, ...{ refreshTokenInformation: action.payload } };
    case RECEIVE_UPDATE_CURRENT_USER:
      return { ...state, ...action.payload };
    case REQUEST_TOKEN_DIALOG:
      return { ...state, ...{ refreshTokenInformation: action.payload } };
    case USER_ACTIVE:
      return { ...state, ...{ isActive: true } };
    case USER_INACTIVE:
      return { ...state, ...{ isActive: false } };
    case DISMISS_SESSION_EXPIRED_DIALOG:
      return { ...state, ...{ refreshTokenInformation: undefined } };
    case REQUEST_SEND_EMAIL_VALIDATION:
      const currentProfile = state.profile;
      if (currentProfile !== undefined) {
        currentProfile.emailValidationStatus = action.payload;
      }
      return { ...state };
    case RECEIVE_CONFIRM_EMAIL_VALIDATION:
      return { ...state, ...{ emailValidationResult: action.payload } };
    case USER_DELETED_LOGGED_OUT:
      return {
        ...state,
        ...{
          refreshTokenInformation: {
            hasBeenAutomaticallyLoggedOut: false,
            hasBeenLoggedOutFromElsewhere: false,
            isAboutToExpire: false,
            hasBeenDeletedAndLoggedOut: true,
            secondsToLogout: 0,
            userLoggedOut: false,
          },
        },
      };
    case USER_LOGGED_OUT:
      return {
        ...state,
        ...{
          refreshTokenInformation: {
            hasBeenAutomaticallyLoggedOut: false,
            hasBeenLoggedOutFromElsewhere: false,
            isAboutToExpire: false,
            hasBeenDeletedAndLoggedOut: false,
            secondsToLogout: 0,
            userLoggedOut: true,
          },
        },
      };
    default:
      return state;
  }
};
