import { createAction, IActionWithPayload } from "../actionHelper";

import { apiGET } from "../../api";
import { AppState } from "../../reducers";
import { ICompanyResult } from "../../containers/message/models/ICompanyResult";

const RECEIVE_CVR_COMPANY = "RECEIVE_CVR_COMPANY";
const RECEIVE_EAN_COMPANY = "RECEIVE_EAN_COMPANY";

export interface ICvrReducerState {
  cvrCompanyMap: Map<string, IKeyAndCompany>;
  eanCompanyMap: Map<string, IKeyAndCompany>;
}

interface IKeyAndCompany {
  key: string;
  company: ICompanyResult | undefined;
  isLoading: boolean;
}

const getCvrCompany = (cvr: string) => apiGET<ICompanyResult>(`api/cvr/${cvr}`);
const getEanCompany = (ean: string) => apiGET<ICompanyResult>(`api/cvr/ean/${ean}`);

const receiveCvrCompany = (company: IKeyAndCompany) => createAction(RECEIVE_CVR_COMPANY, company);
const receiveEanCompany = (company: IKeyAndCompany) => createAction(RECEIVE_EAN_COMPANY, company);

const getCvr = (cvr: string) => async (dispatch: any, getState: () => AppState) => {
  const { companies } = getState();
  if (
    !(
      companies.cvrCompanyMap.has(cvr) &&
      (companies.cvrCompanyMap.get(cvr)?.company?.statusCode === 200 || companies.cvrCompanyMap.get(cvr)?.company?.statusCode === 404)
    )
  ) {
    dispatch(receiveCvrCompany({ key: cvr, company: undefined, isLoading: true }));
    const companyResult = await getCvrCompany(cvr);
    dispatch(receiveCvrCompany({ key: cvr, company: companyResult, isLoading: false }));
  }
};

const getEan = (ean: string) => async (dispatch: any, getState: () => AppState) => {
  const { companies } = getState();
  if (
    !(
      companies.eanCompanyMap.has(ean) &&
      (companies.eanCompanyMap.get(ean)?.company?.statusCode == 200 || companies.eanCompanyMap.get(ean)?.company?.statusCode === 404)
    )
  ) {
    dispatch(receiveEanCompany({ key: ean, company: undefined, isLoading: true }));
    const companyResult = await getEanCompany(ean);
    dispatch(receiveEanCompany({ key: ean, company: companyResult, isLoading: false }));
  }
};

export interface ICvrActions {
  getCvr: (cvr: string) => void;
  getEan: (ean: string) => void;
}

export const CvrActions: ICvrActions = {
  getCvr,
  getEan,
};

const defaultState: ICvrReducerState = {
  cvrCompanyMap: new Map<string, IKeyAndCompany>(),
  eanCompanyMap: new Map<string, IKeyAndCompany>(),
};

export default (state: ICvrReducerState = defaultState, action: IActionWithPayload<string, IKeyAndCompany>): ICvrReducerState => {
  switch (action.type) {
    case RECEIVE_CVR_COMPANY:
      return {
        ...state,
        cvrCompanyMap: state.cvrCompanyMap.set(action.payload.key, action.payload),
      };
    case RECEIVE_EAN_COMPANY:
      return {
        ...state,
        eanCompanyMap: state.eanCompanyMap.set(action.payload.key, action.payload),
      };
    default:
      return state;
  }
};
