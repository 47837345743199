import * as React from "react";
import { Link } from "react-router-dom";
import { IGlobalErrorProps, AlertComponent } from "./AlertComponent";
import Helmet from "react-helmet";

const Error: React.FC<IGlobalErrorProps> = (props: IGlobalErrorProps) => {
  document.title = "Statstidende | Fejl";

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <AlertComponent header="Vi beklager!" correlationId={props.correlationId}>
        <p>
          Der er sket en uventet systemfejl på Statstidende. Fejlen er blevet logget. Venligst gå til <Link to="/">forsiden</Link>.
        </p>
      </AlertComponent>
    </>
  );
};

export default Error;
