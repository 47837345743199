import * as React from "react";
import { WidgetProps } from "react-jsonschema-form";
import Input from "reactstrap/lib/Input";

export const DecimalNumberWidget: React.FC<WidgetProps> = props => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.value.replace(/[^0-9,.]/g, "");
    e.target.value = newVal;
    props.onChange(newVal || undefined);
  };

  return <Input pattern={"[0-9]*"} type="text" id={props.id} onChange={onChange} value={props.value || ""} disabled={props.disabled} />;
};
