import * as React from "react";
import axios from "axios";
import * as FileSaver from "file-saver";

interface IDownloadLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  url: string;
  hideLink?: boolean;
  children?: React.ReactNode;
}

const DownloadLink: React.FC<IDownloadLinkProps> = ({ url, className, title, children, hideLink }) => {
  const download = async (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();

    const result = await axios.get(`${url}`, {
      responseType: "blob",
    });
    let filename: string = "";
    const contentDisposition = result.headers["content-disposition"];
    if (contentDisposition) {
      const dispositions: string[] = contentDisposition.split("; ");

      dispositions.forEach(x => {
        if (x.startsWith("filename=")) {
          filename = x.slice("filename=".length);
        }
      });
      filename = filename.replace(`"`, ``);
    }

    if (filename !== "") {
      FileSaver.saveAs(result.data, filename.replace(`"`, ``)); // ser mærkeligt ud, men virker.
    } else {
      FileSaver.saveAs(result.data);
    }
  };

  return (
    <a className={className} href={hideLink ? "#" : url} title={title} onClick={download}>
      {children}
    </a>
  );
};

export default DownloadLink;
