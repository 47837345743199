import { IPublicationPatternModal } from "../../containers/editorial/productioncalendar/ProductionCalendarDates";
import { PublicationPatternEnum } from "../../reducers/editMessage/IEditMessageState";

export interface IMonth {
  monthNumber: number;
  displayName: string;
}

export interface IProductionCalendarData {
  nextPublicationDay: Date;
  oldestPublicationDate: Date;
  days: IProductionCalendarDay[];
}

export interface IFrontPageDeadlineModel {
  nextNormalDeadline: IPublicationPatternModal;
  nextMortificationDeadlines: IPublicationPatternModal[];
}

export interface IProductionCalendarDay {
  isPublicationDate: boolean;
  date: Date;
  states: IProductionCalendarDayState[];
  numberOfMessages: number;
  publicationFileUniqueId?: string;
  publicationNumber?: number;
  hasSignature: boolean;
  dayName: string;
}

export interface IProductionCalendarDayState {
  displayName: string;
  value: number;
  publicationPattern: PublicationPatternEnum;
}

export interface IProductionCalendarDayMessages {
  title: string;
  total: number;
  publicKey?: string;
  r: number;
  k: number;
  c: number;
  a: number;
  g: number;
  w: number;
  nested: IProductionCalendarDayMessages[];
}

export interface IProductionCalendarOverviewModel {
  messages: IProductionCalendarDayMessages;
  states: IProductionCalendarDayState[];
  canStartPreparePublication: boolean;
}

export interface IProductionLog {
  created: Date;
  userProfileName: string;
  text: string;
}

export enum PublicationCalendarDateRangeEnum {
  Day = 1,
  Month = 2,
  Year = 3,
}
