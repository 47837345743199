import { all } from "redux-saga/effects";
import { userSagas } from "./reducers/user/userReducer";
import { sectionSagas } from "./reducers/sections/sectionsReducer";
import { messageSagas } from "./reducers/message/messageReducer";
import editMessageSagas from "./reducers/editMessage/EditMessageSagas";
import { publicationDateSagas } from "./reducers/publicationDate/publicationDateReducer";
import { jurisdictionsSagas } from "./reducers/jurisdictions/jurisdictionsReducer";

export default function* root() {
  yield all([...userSagas, ...editMessageSagas, ...messageSagas, ...sectionSagas, ...publicationDateSagas, ...jurisdictionsSagas]);
}
