import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { routes } from "./routes";
import * as serviceWorker from "./serviceWorker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import da from "date-fns/locale/da";
registerLocale("da", da);
setDefaultLocale("da");

const store = configureStore();

// used by react-intl to set locale-data to "danish" e.g. show danish date/time format etc.
require("@formatjs/intl-pluralrules/polyfill");
require("@formatjs/intl-pluralrules/locale-data/da"); // Add locale data for de

require("@formatjs/intl-relativetimeformat/polyfill");
require("@formatjs/intl-relativetimeformat/locale-data/da"); // Add locale data for de

const Root = () => (
  <Provider store={store}>
    <IntlProvider locale="da" defaultLocale="da" formats={{ date: { "year-only": { year: "numeric" } } }}>
      <BrowserRouter>{routes}</BrowserRouter>
    </IntlProvider>
  </Provider>
);

render(<Root />, document.getElementById("root-app"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
