import { ActionsUnion, createAction } from "../actionHelper";
import { ParsedParams, parseWithDefaults, parseQueryString, IParsable } from "@lexdania/components";

const SET_SEARCHSERVICECLIENTUSERPARAMS = "SET_SEARCHSERVICECLIENTUSERPARAMS";

export interface IServiceClientSearchParams extends IParsable {
  page: number;
  ps: number;
  t: string[];
  o: number;
  d: boolean;
  isActive: boolean;
  isDisabled: boolean;
  s: string[];
}

export interface IServiceClientSearchState {
  params: string;
  paramsParsed: ParsedParams;
  parseWithDefaults: (defaults: IServiceClientSearchParams) => IServiceClientSearchParams;
}

export const setSearchParams = (item: string) => createAction(SET_SEARCHSERVICECLIENTUSERPARAMS, item);

// Action Exports
export interface IUserSearchServiceClientActions {
  setSearchParams: (params: string) => void;
}

export const UserSearchServiceClientActions: IUserSearchServiceClientActions = {
  setSearchParams,
};

const defaultState: IServiceClientSearchState = {
  params: "",
  paramsParsed: {},
  parseWithDefaults: defaults => parseWithDefaults({}, defaults), // !Important to keep this as it does a spread of the "empty marker arrays" EmptyNumberArray and EmptyNumberArray
};

const Actions = { setSearchParams };
type SearchServiceClientUserActions = ActionsUnion<typeof Actions>;

export default (state: IServiceClientSearchState = defaultState, action: SearchServiceClientUserActions): IServiceClientSearchState => {
  switch (action.type) {
    case SET_SEARCHSERVICECLIENTUSERPARAMS:
      const params = action.payload;
      const parsed: ParsedParams = parseQueryString(params);
      return {
        ...state,
        params: params,
        paramsParsed: parsed,
        parseWithDefaults: defaults => parseWithDefaults(parsed, defaults),
      };
    default:
      return state;
  }
};
