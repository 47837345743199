import { combineReducers } from "redux";
import editMessage from "./reducers/editMessage/EditMessageReducer";
import texts from "./reducers/texts/textsReducer";
import faq from "./reducers/faq/faqReducer";
import sections from "./reducers/sections/sectionsReducer";
import news from "./reducers/news/newsReducer";
import user from "./reducers/user/userReducer";
import publications from "./reducers/publications/publicationsReducer";
import config from "./reducers/config/configReducer";
import productionCalendar from "./reducers/productionCalendar/productionCalendar";
import { IPublicationDateModel } from "./reducers/publicationDate/PublicationDateModel";
import publicationDateModel from "./reducers/publicationDate/publicationDateReducer";
import messages from "./reducers/message/messageReducer";
import search from "./reducers/search/searchReducer";
import searchUser from "./reducers/searchUser/searchUserReducer";
import searchServiceClient from "./reducers/searchServiceClientUser/searchServiceClientUserReducer";
import signingCertificates from "./reducers/SigningCertificates/SigningCertificatesReducer";
import { IJobSummary } from "./reducers/jobSummary/jobSummaryModels";
import jobSummary from "./reducers/jobSummary/jobSummaryReducer";
import { ICountry } from "./reducers/country/countryInfo";
import country from "./reducers/country/countryReducer";
import jurisdictions from "./reducers/jurisdictions/jurisdictionsReducer";
import searchTeams from "./reducers/teams/teamsReducer";
import searchMessageLogs from "./reducers/messagelog/messagelogReducer";
import companies from "./reducers/cvr/cvrReducer";

export interface IRootReducer {
  publicationDateModel: IPublicationDateModel;
  jobSummary: IJobSummary;
  logSearchBaseUrl: string;
  country: ICountry;
}

const rootReducer = combineReducers({
  editMessage,
  productionCalendar,
  messages,
  publications,
  user,
  news,
  search,
  searchUser,
  sections,
  texts,
  config,
  publicationDateModel,
  faq,
  signingCertificates,
  jobSummary,
  searchServiceClient,
  country,
  jurisdictions,
  searchTeams,
  searchMessageLogs,
  companies,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
