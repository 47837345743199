import { TimeFormats, TimePicker } from "@lexdania/components";
import * as React from "react";
import { WidgetProps } from "react-jsonschema-form";

export class CustomTimeWidget extends React.Component<WidgetProps, {}> {
  constructor(props: WidgetProps) {
    super(props);
  }

  public render() {
    return (
      <TimePicker
        selected={this.props.value}
        onChange={this.props.onChange}
        timeFormat={TimeFormats.Default}
        timeInterval={5}
        timePickerTitle="Tid"
        disabled={this.props.disabled}
        id={this.props.id}
      />
    );
  }
}
