import { ICountry, ICountryCodeInfo, ICountryCode } from "./countryInfo";
import { createAction, ActionsUnion } from "../../reducers/actionHelper";
import { Dispatch } from "react";
import { IRootReducer } from "../../reducers";
import { apiGET } from "../../api";

export enum CountryActionEnum {
  RECEIVE_COUNTRYCODEINFO = "RECEIVE_COUNTRYCODEINFO",
}
const receiveCountryCodeInfo = (countryCodes: ICountryCode[]) => createAction(CountryActionEnum.RECEIVE_COUNTRYCODEINFO, countryCodes);
export const Actions = { receiveCountryInfo: receiveCountryCodeInfo };
type CountryActions = ActionsUnion<typeof Actions>;

// Fetch
const getCountryCodeInfo = () => async (dispatch: Dispatch<CountryActions>, getState: () => IRootReducer) => {
  const { countryCodes } = getState().country;
  if (countryCodes.length > 0) {
    return;
  }
  const countryCodeInfo = await apiGET<ICountryCodeInfo>("api/CountryCode");
  dispatch(receiveCountryCodeInfo(countryCodeInfo.countryCodes));
};

export interface ICountryActions {
  getCountryCodeInfo(): Promise<void>;
}

export const ConnectCountryActions = { getCountryCodeInfo: getCountryCodeInfo };

// Reducer
const initialState: ICountry = {
  countryCodes: [],
};
export default (state = initialState, action: CountryActions): ICountry => {
  switch (action.type) {
    case CountryActionEnum.RECEIVE_COUNTRYCODEINFO: {
      return { ...state, countryCodes: action.payload };
    }
    default:
      return state;
  }
};
