import { IJobSummary } from "./jobSummaryModels";
import { createAction, ActionsUnion } from "../../reducers/actionHelper";
import { IRootReducer } from "../../reducers";
import { Dispatch } from "redux";
import { apiGET } from "../../api";

// Actions
export enum JobSummaryActionEnum {
  RECEIVE_JOBSUMMARY = "RECEIVE_JOBSUMMARY",
}
const receiveJobSummary = (jobSummary: IJobSummary) => createAction(JobSummaryActionEnum.RECEIVE_JOBSUMMARY, jobSummary);
export const Actions = { receiveJobSummary };
type JobSummaryActions = ActionsUnion<typeof Actions>;

// Fetch
const getJobSummary = () => async (dispatch: Dispatch<JobSummaryActions>, getState: () => IRootReducer) => {
  const jobSummaryState = getState().jobSummary;

  if (jobSummaryState.jobLogs.length > 0) {
    return;
  }

  const jobSummary = await apiGET<IJobSummary>("api/joblog/jobsummary");

  dispatch(receiveJobSummary(jobSummary));
};

export interface IJobSummaryReducerActions {
  getJobSummary(): Promise<void>;
}

export const JobSummaryReducerActions = { getJobSummary };

// Reducer
const initialState: IJobSummary = {
  jobLogs: [],
  otherJobLogs: [],
};

export default (state = initialState, action: JobSummaryActions): IJobSummary => {
  switch (action.type) {
    case JobSummaryActionEnum.RECEIVE_JOBSUMMARY: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
