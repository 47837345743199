import * as React from "react";
import { IUserActions, IUserState, UserActions } from "../../reducers/user";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IdleTimer from "react-idle-timer";

interface IIdleUserDetectorProps extends IUserActions, RouteComponentProps<{}> {
  user: IUserState;
}

class IdleUserDetector extends React.Component<IIdleUserDetectorProps, {}> {
  constructor(props: IIdleUserDetectorProps) {
    super(props);
  }

  public render() {
    return <IdleTimer onActive={this.props.userActive} onIdle={this.props.userInactive} debounce={250} timeout={1000 * 60} />;
  }
}

function mapStateToProps({ user }: any, ownProps: any) {
  return { ...ownProps, user };
}
export default withRouter(connect(mapStateToProps, dispatch => bindActionCreators({ ...UserActions }, dispatch))(IdleUserDetector));
