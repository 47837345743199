import * as React from "react";
import "./GlobalError.scss";
import { Icon, IconType } from "@lexdania/components";
import Helmet from "react-helmet";

interface IErrorInfo {
  componentStack: string;
}
interface IErrorState {
  hasError: boolean;
  error?: Error;
  info?: IErrorInfo;
  showDetails: boolean;
}

export class GlobalErrorBoundary extends React.Component<any, IErrorState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, showDetails: false };
    this.toggleDetails = this.toggleDetails.bind(this);
  }
  public componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true, error: error, info: info });
    this.logError(error, info);
  }
  public render() {
    if (!this.state.hasError) {
      return this.props.children;
    }
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        <div className="container global-error">
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Vi beklager!</h4>
            <p>
              Der er sket en uventet systemfejl på Statstidende. Fejlen er blevet logget. Venligst gå til <a href="/">forsiden</a>.
            </p>
            <button onClick={this.toggleDetails} className="showDetailsButton">
              <Icon icon={this.state.showDetails ? IconType.AngleDown : IconType.AngleRight} />
              <span>Vis detaljer</span>
            </button>
            <div className={this.state.showDetails ? "collapse show" : "collapse"}>
              <div className="card card-body">
                <pre>
                  <code>
                    {this.state.error && this.state.error.stack}
                    <br />
                    Component stack:
                    {this.state.info && this.state.info.componentStack}
                  </code>
                </pre>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  private toggleDetails(): void {
    this.setState(prevState => ({
      showDetails: !prevState.showDetails,
    }));
  }
  private logError(error: any, info: any): void {
    // TODO Log on server
    console.log("Error: " + error);
  }
}
