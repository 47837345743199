import { createAction } from "../actionHelper";
import { apiGET } from "../../api";

export enum ConfigAction {
  RECEIVE_CONFIG = "RECEIVE_CONFIG",
}

export interface IConfigState {
  loginSiteUrl?: string;
  numberOfNewsToShowOnFrontpage?: number;
  cityNameProvider?: string;
  logSearchBaseUrl?: string;
  logSearchIndexPattern?: string;
  wasUrl?: string;
}

const receiveConfig = (config: IConfigState) => createAction(ConfigAction.RECEIVE_CONFIG, config);

const getConfig = () => async (dispatch: any, getState: () => IConfigState) => {
  const loginSiteUrl = getState().loginSiteUrl;

  if (loginSiteUrl) {
    return;
  }

  dispatch(receiveConfig(await apiGET<IConfigState>("api/config")));
};

export interface IConfigActions {
  getConfig: () => void;
}

export const ConfigActions: IConfigActions = {
  getConfig,
};

export default (state: IConfigState = {}, action: any): IConfigState => {
  switch (action.type) {
    case ConfigAction.RECEIVE_CONFIG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
