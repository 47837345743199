import * as React from "react";
import { WidgetProps } from "react-jsonschema-form";
import { DateFormats, DatePicker } from "@lexdania/components";
import { format, parse } from "date-fns";
import { DateRuleType } from "../../../reducers/editMessage/IEditMessageState";
import { ISelectableDate } from "../date-validator/ISelectableDate";
import { IValidDate } from "@lexdania/components/build/lib/components/date-picker/DatePicker";

export class CustomDateWidget extends React.Component<WidgetProps, {}> {
  private dateFormat: string = DateFormats.Default;
  constructor(props: WidgetProps) {
    super(props);
  }

  public onDatePicked = (date: Date | null) => {
    const formattedDate = date !== null ? format(date, this.dateFormat) : undefined;
    this.props.onChange(formattedDate);
  };

  public render() {
    const { dateRule, validDates } = this.props.options;

    let maxDate = dateRule === DateRuleType.BeforeOrEqualToday ? new Date() : null;
    let minDate = dateRule === DateRuleType.EqualOrAfterToday ? new Date() : null;

    maxDate?.setHours(23, 59, 59, 999);
    minDate?.setHours(0, 0, 0, 0);

    let selected: Date | null = null;
    if (this.props.value === undefined || this.props.value === null) {
      selected = null;
    } else {
      const parseValue = parse(this.props.value, this.dateFormat, new Date());
      selected = parseValue.valueOf().toString() !== "NaN" ? parseValue : null;
    }

    let selectableDates: ISelectableDate[] = [];
    if (validDates != null) {
      selectableDates = JSON.parse(validDates.toString());
      minDate = parse(selectableDates[0].date, DateFormats.Default, new Date());
      maxDate = parse(selectableDates[selectableDates.length - 1].date, DateFormats.Default, new Date());
    }

    const selectedDateUserNotification = selectableDates.find(x => parse(x.date, DateFormats.Default, new Date()).getTime() === selected?.getTime())
      ?.userNotification;

    const includeDateIntervals =
      minDate !== null ? [{ start: minDate, end: new Date(9999, 1, 1) }] : maxDate !== null ? [{ start: new Date(0, 0, 1), end: maxDate }] : undefined;

    return (
      <>
        <DatePicker
          disabled={this.props.disabled}
          autoComplete="off"
          id={this.props.id}
          className="form-control"
          selected={selected}
          onChange={this.onDatePicked}
          dateFormat={this.dateFormat}
          /* Notice, setting min and/or max date makes the keyboard navigation not work (ver 4.25.0) */
          includeDateIntervals={includeDateIntervals}
          validDates={selectableDates.map<IValidDate>(x => ({
            date: parse(x.date, DateFormats.Default, new Date()),
            specialDate: x.userNotification?.length > 0,
          }))}
        />
        {selectedDateUserNotification && (
          <ul className="warning-detail bs-callout bs-callout-info">
            <li className="text-warning">{selectedDateUserNotification}</li>
          </ul>
        )}
      </>
    );
  }
}
