import { Icon, IconType } from "@lexdania/components";
import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import "./Help-icon.scss";

interface IHelpIconProps {
  helpText: string;
  id: string;
}

export const HelpIcon: React.FC<IHelpIconProps> = props => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipPinnedOpen, setTooltipPinnedOpen] = useState(false);

  return (
    <>
      {props.helpText && (
        <>
          <a
            className="help-icon"
            tabIndex={0}
            id={"helpIcon_" + props.id}
            onClick={e => {
              e.preventDefault();

              setTooltipPinnedOpen(!tooltipPinnedOpen);
              if (!tooltipPinnedOpen) {
                setTimeout(() => {
                  setTooltipPinnedOpen(false);
                  setTooltipOpen(false);
                }, 30000);
              }
            }}
            aria-label="Vis hjælpetekst"
          >
            <Icon icon={IconType.QuestionCircle} solid={true}></Icon>
          </a>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen || tooltipPinnedOpen}
            target={"helpIcon_" + props.id}
            toggle={e => {
              setTooltipOpen(!tooltipOpen);
            }}
            className="help-icon-tooltip"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: props.helpText,
              }}
            ></div>
          </Tooltip>
        </>
      )}
    </>
  );
};
