import * as React from "react";
import "./WizardProgressBar.scss";

export interface IWizardProgressBarProps {
  stepList: string[];
  selected: number;
}

export const WizardProgressBar = (props: IWizardProgressBarProps) => {
  return (
    <div className="wizard-progress-bar d-none d-lg-block d-xl-block">
      {props.stepList.map((step: string, index: number) => (
        <div key={index} className={props.selected === index ? "wizard-step active" : "wizard-step"}>
          <div className="wizard-step-content">
            <div className="wizard-content-text">
              <h2>{index + 1}.</h2>
              <h3>{step}</h3>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
