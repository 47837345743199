import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IEditMessageState } from "../../../reducers/editMessage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ConfigActions, IConfigState } from "../../../reducers/config/configReducer";
import { IUserState, UserActions } from "../../../reducers/user";
import { IUserActions } from "../../../reducers/user/userReducer";
import { DropDownMenuBuilder } from "../drop-down-menu-builder";
import { Header, TopMenu, TopMenuItem, Icon, IconType, ConfirmModal } from "@lexdania/components";
import { EditMessageActions } from "../../../reducers/editMessage/EditMessageActions";
import { AdminNavigationList } from "../menu/UserNavigationList";

interface IHeaderProps extends IUserActions, RouteComponentProps<{}> {
  editMessage: IEditMessageState;
  user: IUserState;
  startNew: () => void;
  config?: IConfigState;
  getConfig?: any;
}

interface IHeaderState {
  showMessageModal: boolean;
  menuOpen: boolean;
}

class HeaderContainer extends React.Component<IHeaderProps, IHeaderState> {
  public readonly state = {
    menuOpen: false,
    showMessageModal: false,
  };

  public componentDidMount() {
    this.props.getConfig();
  }

  public handleMenuClick = (e: any) => {
    e.preventDefault();
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  public closeHandler = () => {
    this.setState({ menuOpen: false });
  };

  public render() {
    const { user, config } = this.props;
    const { token } = user;
    const { location } = window;
    const { pathname, search, origin } = location;

    const loginUrl = !config
      ? ""
      : config.loginSiteUrl + "/idplogin.ashx?myReturnUrl=" + encodeURIComponent(origin + "/LoginHandler?returnUrl=" + pathname + search);

    const isPage = (pageType: string) => pathname.indexOf(`/${pageType}`) !== -1;
    const isAdmin = isPage("administration");

    const dropdownmenu = token && this.state.menuOpen && <DropDownMenuBuilder onLogout={this.props.userLogOut} onClose={this.closeHandler} token={token} />;
    const submenu = user.profile && isAdmin && (
      <div className="nav-level-3">
        <AdminNavigationList />
      </div>
    );
    return (
      <React.Fragment>
        <ConfirmModal
          showModal={this.state.showMessageModal}
          onSelection={this.handleConfirmStartInsertion}
          header="Bekræft"
          text="Vil du starte på en ny indrykning?"
        />
        <Header logoText={"Statstidende.dk"} dropdownmenu={dropdownmenu} submenu={submenu}>
          <TopMenu>
            <TopMenuItem onClick={this.handleAddMessageClick} icon={IconType.Plus} iconSize={18} text="Indryk meddelelse" url="/messages/add" />
            <TopMenuItem icon={IconType.Search} text="Søg" url="/messages" iconSize={18} />

            {config &&
              config.loginSiteUrl &&
              (!user.profile ? (
                <li className="nav-item">
                  <a href={loginUrl}>
                    <span className="d-none d-lg-block d-xl-blockn h4">Log på</span> <Icon icon={IconType.UnlockAlt} iconSize={20} />
                  </a>
                </li>
              ) : (
                <li className="nav-item">
                  <a onClick={this.handleMenuClick} id="navbarDropdown" role="button" href="">
                    <span className="d-none d-lg-block d-xl-block h4">Menu</span>
                    <Icon icon={IconType.Bars} iconSize={20} />
                  </a>
                </li>
              ))}
          </TopMenu>
        </Header>
      </React.Fragment>
    );
  }

  private handleAddMessageClick = (e: any) => {
    const { location } = this.props;
    if (location.pathname !== "/messages/add") {
      return;
    }
    this.setState({ showMessageModal: true });
    e.preventDefault();
  };

  private handleConfirmStartInsertion = (startInsertion: boolean): void => {
    this.setState({ showMessageModal: false });
    if (!startInsertion) {
      return;
    }
    this.props.startNew();
    this.props.history.push("/messages/add");
  };
}

function mapStateToProps({ config, editMessage, user }: any, ownProps: any) {
  return { ...ownProps, editMessage, user, config };
}

export default withRouter(
  connect(mapStateToProps, dispatch => bindActionCreators({ ...EditMessageActions, ...ConfigActions, ...UserActions }, dispatch))(HeaderContainer)
);
