import * as React from "react";
import axios from "axios";
import * as FileSaver from "file-saver";
import { IPublicationDownloaderProps } from "./Types";

export default class PublicationDownloader extends React.Component<IPublicationDownloaderProps, {}> {
  public render() {
    const { children } = this.props;
    return <React.Fragment>{children(this.downloadFile)}</React.Fragment>;
  }

  public downloadFile = async (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const { fileName, publicationId, type } = this.props;
    const result = await axios.get(`/api/publication/${publicationId}/${type}`, {
      headers: {
        ...axios.defaults.headers.common,
        Accept: `application/${type}`,
        "Content-Type": `application/${type}`,
      },
      responseType: "blob",
    });
    FileSaver.saveAs(result.data, `${fileName}.${type}`);
  };
}
