import { take, call, all, fork, select, put } from "redux-saga/effects";
import { EditMessageActionsTypes as ActionEnum, clearEditMessage } from "./EditMessageActions";
import { InitialState } from "./EditMessageReducer";
import { IMessageTypeSchema } from "./IEditMessageState";
import { createAction } from "./../actionHelper";
import {
  GETFieldsSchema,
  GETDefaultFieldsSchema,
  GETDefaultFormData,
  GETEditMessage,
  IEditMessageModel,
  IMessageTypesResponse,
  IMessageValidationResult,
  POSTSaveMessage,
} from "./EditMessageAPI";

const constructMessageTypeSchema = (fields: IMessageTypesResponse, defaultFields: IMessageTypesResponse): IMessageTypeSchema => {
  return {
    sectionName: fields.sectionName,
    fieldsSchema: JSON.parse(fields.jsonSchema),
    fieldsUiSchema: JSON.parse(fields.uiSchema),
    defaultFieldsSchema: JSON.parse(defaultFields.jsonSchema),
    defaultFieldsUiSchema: JSON.parse(defaultFields.uiSchema),
  };
};

function* watchResetditFlow() {
  while (true) {
    yield take(ActionEnum.START_NEW);
    const { editMessage } = yield select();
    // If something is different from how we started. Reset flow
    if (editMessage !== InitialState) {
      yield put(clearEditMessage());
    }
  }
}

function* watchSaveMessage() {
  const savedEditMessage = (messageValidationResult: IMessageValidationResult) => createAction(ActionEnum.SAVED_EDIT_MESSAGE, messageValidationResult);
  const requestSavedMessageAction = (messageNumber: string) => createAction(ActionEnum.REQUEST_EDIT_MESSAGE, messageNumber);

  while (true) {
    const { payload } = yield take(ActionEnum.REQUEST_SAVE_MESSAGE);
    const { fieldsData, defaultFieldsData } = payload;
    const { editMessage } = yield select();
    const { messageNumber, messageTypeId, messageState, concurrencyToken } = editMessage;

    const message: IEditMessageModel = {
      messageNumber,
      messageTypeId,
      fieldsData: JSON.stringify(fieldsData ? fieldsData : {}),
      defaultFieldsData: JSON.stringify(defaultFieldsData ? defaultFieldsData : {}),
      state: messageState,
      concurrencyToken: concurrencyToken,
    };

    const response: IMessageValidationResult = yield call(POSTSaveMessage, message);
    if (response.isValid) {
      yield put(requestSavedMessageAction(response.messageNumber));
    }
    yield put(savedEditMessage(response));
  }
}

function* watchGetEditMessage(): any {
  const receive = (message: IEditMessageModel, messageTypeSchema: IMessageTypeSchema) =>
    createAction(ActionEnum.RECEIVE_EDIT_MESSAGE, { message, messageTypeSchema });

  while (true) {
    const { payload: messageNumber } = yield take(ActionEnum.REQUEST_EDIT_MESSAGE);
    const message: IEditMessageModel = yield call(GETEditMessage, messageNumber);

    const id = message.messageTypeId;
    // Fetch Schema data
    const [fields, defaultFields] = yield all([call(GETFieldsSchema, id), call(GETDefaultFieldsSchema, id)]);
    // construct Schema
    const messageTypeSchema = yield call(constructMessageTypeSchema, fields, defaultFields);
    yield put(receive(message, messageTypeSchema));
  }
}

function* watchSetMessageType(): any {
  const receive = (message: IEditMessageModel, messageTypeSchema: IMessageTypeSchema) =>
    createAction(ActionEnum.RECEIVE_SET_MESSAGE_TYPE, { message, messageTypeSchema });

  while (true) {
    // Take messageTypeId we want to work on
    const { payload: id } = yield take(ActionEnum.REQUEST_SET_MESSAGE_TYPE);
    yield put({ type: ActionEnum.CLEAR_FIELD_SCHEMA });
    // Get both fields and defaultFields
    if (id !== -1) {
      const [fields, defaultFields, fieldDefault] = yield all([call(GETFieldsSchema, id), call(GETDefaultFieldsSchema, id), call(GETDefaultFormData, id)]);
      const messageTypeSchema = yield call(constructMessageTypeSchema, fields, defaultFields);
      const { messageNumber, messageTypeId, fieldsData, defaultFieldsData, state, concurrencyToken } = fieldDefault;
      const message: IEditMessageModel = {
        messageNumber,
        messageTypeId,
        fieldsData: fieldsData ? fieldsData : "",
        defaultFieldsData: defaultFieldsData ? defaultFieldsData : "",
        state,
        concurrencyToken,
      };

      yield put(receive(message, messageTypeSchema));
    }
  }
}

export default [fork(watchResetditFlow), fork(watchSetMessageType), fork(watchGetEditMessage), fork(watchSaveMessage)];
