import * as React from "react";
import { UncontrolledCollapse } from "reactstrap";

interface ITreeNodeProps {
  node: INode;
  parentNode?: INode;
  onNodeToggled: (node: INode) => void;
  onNodeSelected: (node: INode) => void;
  index: number;
  nodeId: string;
}

export interface INode {
  id?: string;
  name?: string;
  children?: INode[];
  tag?: string;
  element: any;
  hasChildren: boolean;
  subtitle?: ISubtitleField[];
  canEdit: boolean;
}

export interface ISubtitleField {
  name: string;
  value: string;
}

export class TreeNode extends React.Component<ITreeNodeProps, {}> {
  public handleOnNodeClick = () => {
    const { node, onNodeSelected } = this.props;

    if (onNodeSelected) {
      onNodeSelected(node);
    }
  };

  public onNodeToggled = (node: INode) => (e: React.SyntheticEvent<HTMLAnchorElement>) => this.props.onNodeToggled(node);

  public render(): any {
    const { nodeId, node, onNodeSelected, onNodeToggled } = this.props;
    const id: string = nodeId;
    return (
      <li>
        <a onClick={this.handleOnNodeClick} className={"tree-header " + (node.canEdit ? "" : "no-target")}>
          {node.name}
        </a>
        {node.subtitle &&
          node.subtitle.map((s, i) => (
            <div className="subtitle" key={`summary-${i}`}>
              {s.name.toString()} {s.value.toString()}
            </div>
          ))}
        {node.children && (
          <UncontrolledCollapse toggler={`#${id}`}>
            {node.children.length > 0 &&
              node.children.map((childNode: INode, i: number, array: INode[]) => (
                <ul key={nodeId + "_" + childNode.id}>
                  <TreeNode
                    nodeId={nodeId + "_" + childNode.id}
                    index={i}
                    node={childNode}
                    parentNode={node}
                    onNodeToggled={onNodeToggled}
                    onNodeSelected={onNodeSelected}
                  />
                </ul>
              ))}
          </UncontrolledCollapse>
        )}
        {node.hasChildren && (
          <a
            onClick={this.onNodeToggled(node)}
            id={id}
            className="toggler clear-both"
            role="button"
            aria-label={"Folder " + node.name + " ind og ud"}
            href=""
          />
        )}
      </li>
    );
  }
}
