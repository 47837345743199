import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
const qs = require("query-string");
import { IConfigState, ConfigActions, IConfigActions } from "../../reducers/config/configReducer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

interface ILoginProps extends RouteComponentProps<any>, IConfigActions {
  config?: IConfigState;
}

class Login extends React.Component<ILoginProps, any> {
  constructor(props: ILoginProps) {
    super(props);
  }

  public componentDidMount() {
    this.props.getConfig();
  }

  public render() {
    const { config } = this.props;
    if (!config) {
      return null;
    }
    if (!config.loginSiteUrl) {
      return null;
    }
    const q = qs.parse(this.props.location.search);
    const returnUrl = q.returnUrl ? q.returnUrl : "/";
    const loginUrl =
      config.loginSiteUrl +
      "/idplogin.ashx?myReturnUrl=" +
      encodeURIComponent(document.location!.origin + "/LoginHandler?returnUrl=" + encodeURIComponent(returnUrl));
    return (
      <div>
        <h1>Log på Statstidende</h1>
        <h2>Opret nye meddelelser og få overblik over tidligere meddelelser.</h2>
        <a id="loginLink" href={loginUrl} className="btn btn-primary">
          Log på med NemID
        </a>
      </div>
    );
  }
}

export default connect(
  ({ config }: any, ownProps: any) => ({ ...ownProps, config }),
  dispatch => bindActionCreators({ ...ConfigActions }, dispatch)
)(Login);
