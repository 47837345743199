import * as React from "react";
import { NavLink } from "react-router-dom";
import { HasClaimPrivilege, StatstidendePrivileges as Privs, IsTeamMember, StatstidendePrivileges } from "../../../services/ClaimService";

interface IUserNavigationProps {
  priv: Privs;
  to: any;
  text: string;
}

const UserNavigation = ({ priv, to: url, text }: IUserNavigationProps) => {
  if (!HasClaimPrivilege(priv)) {
    return null;
  }
  return (
    <li>
      <NavLink to={url}>{text}</NavLink>
    </li>
  );
};

export const UserNavigationItems = ({ linkToTeams }: any) => (
  <React.Fragment>
    <UserNavigation priv={Privs.EditProfile} to="/mypage/profile" text="Min profil" />
    <UserNavigation priv={Privs.AddMessage} to={{ pathname: "/messages", search: "userOnly=true" }} text="Mine meddelelser" />
    {IsTeamMember() && <UserNavigation priv={Privs.AddMessage} to={{ pathname: "/messages", search: linkToTeams }} text="Mine teammeddelelser" />}
  </React.Fragment>
);

export const AdminNavigationItems = () => (
  <React.Fragment>
    <UserNavigation priv={Privs.AdminMessageType} to="/administration/messagetypes" text="Rubrikadministration" />
    <UserNavigation priv={Privs.AdminUsers} to="/administration/users" text="Brugere" />
    <UserNavigation priv={Privs.AdminOwnTeams} to="/administration/teams" text="Teams" />
    <UserNavigation priv={Privs.AdminServiceClients} to="/administration/serviceclients" text="API klienter" />
    <UserNavigation priv={Privs.AdminNews} to="/administration/news" text="Nyheder og driftsinformation" />
    <UserNavigation priv={Privs.AccessInvoiceFiles} to="/administration/invoices" text="Fakturaer" />
    <UserNavigation priv={Privs.AdminJobSummary} to="/administration/jobsummary" text="Joboversigt" />
    <UserNavigation priv={Privs.ViewUserMessageLog} to="/administration/messagelog" text="Meddelelseslog" />
  </React.Fragment>
);

export const EditorNavigationItems = () => (
  <React.Fragment>
    <UserNavigation priv={Privs.AccessProductionCalendar} to="/editorial/calendar" text="Produktionskalender" />
  </React.Fragment>
);

export const UserNavigationList = ({ linkToTeams }: any) => (
  <ul>
    <UserNavigationItems linkToTeams={linkToTeams} />
  </ul>
);
export const AdminNavigationList = () => (
  <ul>
    <AdminNavigationItems />
  </ul>
);
export const EditorNavigationList = () => (
  <ul>
    <EditorNavigationItems />
  </ul>
);
