import * as React from "react";
import { IconType, IconButton } from "@lexdania/components";
import "./Infobar.scss";

export interface IInfobarProps {
  header: string;
  body: string;
}

export interface IInfobarState {
  isOpen: boolean;
}

export default class Infobar extends React.Component<IInfobarProps, IInfobarState> {
  public static defaultProps: Partial<IInfobarProps> = {
    header: "Header",
    body: "body",
  };

  public readonly state: IInfobarState = {
    isOpen: false,
  };

  public onExpandCollaps = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  public render() {
    const { header, body } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="alert alert-warning">
        <div className="row">
          <h5 className="col">{header}</h5>
          <IconButton
            className="float-left"
            onClick={this.onExpandCollaps}
            icon={isOpen ? IconType.ChevronUp : IconType.ChevronDown}
            aria-label="Fold kommentar ud"
          />
        </div>
        <div className="row">
          {isOpen && (
            <div className="col">
              {body.split("\n").map((item, key) => (
                <span key={key}>
                  {item}
                  <br />
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
