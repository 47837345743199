import * as React from "react";
import { withRouter, Redirect } from "react-router-dom";
import GlobalErrorForbidden from "./Forbidden";
import GlobalErrorGeneral from "./GeneralError";
import GlobalErrorNotFound from "./NotFound";
import { connect } from "react-redux";

const Error = (props: any) => {
  let cid = "";
  const { state } = props.location;
  if (state) {
    cid = state.correlationId;
  }

  if (!cid) {
    cid = props.match.params.correlationId;
  }

  switch (state && state.status) {
    case 403:
      const { user } = props;
      if (!user.profile && !user.userLoading) {
        const { returnUrl } = props.location.state;
        const path = props.location !== undefined ? `?returnUrl=${returnUrl}` : "";
        return <Redirect to={`/login${path}`} />;
      }
      return <GlobalErrorForbidden correlationId={cid} />;
    case 404:
      return <GlobalErrorNotFound correlationId={cid} />;
    default:
      return <GlobalErrorGeneral correlationId={cid} />;
  }
};

export default connect(({ user }: any) => ({ user }))(withRouter(Error));
