import * as React from "react";
import Form, { FormValidation, IChangeEvent, UiSchema } from "react-jsonschema-form";
import "./MessageInfo.scss";
import { TransformErrors } from "./SchemaUtil";
import { FileWithThumbnailWidget } from "./FileWithThumbnailWidget";
import { CustomDateWidget } from "./CustomDateWidget";
import { ReadonlyTextWidget } from "./ReadonlyTextWidget";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CvrWidget } from "./CvrWidget";
import { DecimalNumberWidget } from "./DecimalNumberWidget";
import { CustomTimeWidget } from "./CustomTimeWidget";
import StreetNameWidget from "./StreetNameWidget";
import { CustomFieldTemplate } from "./CustomFieldTemplate";
import { EanWidget } from "./EanWidget";

interface IMessageInfoProps {
  onDataChanged: (e: IChangeEvent) => void;
  onSubmit: (e: IChangeEvent) => void;
  formData: object;
  jsonSchema: any;
  uiSchema: any;
  headerRenderFunc?: any;
  buttonsRenderFunc?: any;
  isFormDirty: boolean;
  validate?: (formData: any, errors: any, uiSchema: UiSchema) => any | undefined;
  noOffset?: boolean;
  disabled: boolean;
}

const MessageInfo: React.FC<IMessageInfoProps> = ({
  jsonSchema,
  uiSchema,
  isFormDirty,
  formData,
  disabled,
  onDataChanged,
  onSubmit,
  validate,
  noOffset,
  headerRenderFunc,
  buttonsRenderFunc,
}: IMessageInfoProps) => {
  const [hasErrors, setHasErrors] = React.useState(false);
  const [currentChangeEvent, setCurrentChangeEvent] = React.useState<IChangeEvent<any>>({} as IChangeEvent);

  const setGlyphIconTexts = React.useRef((): void => {
    setGlyphIconsText("glyphicon-arrow-up", "Flyt op");
    setGlyphIconsText("glyphicon-arrow-down", "Flyt ned");
    setGlyphIconsText("glyphicon-remove", "Fjern");
    setGlyphIconsText("glyphicon-plus", "Tilføj");
  });

  const checkForAndFixArrayButtons = React.useCallback(() => {
    setGlyphIconTexts.current();
    const items = document.querySelectorAll(".field-array-of-object > .array-item-list");
    Array.prototype.filter.call(items, (element: HTMLElement) => {
      // Remove "fjern" buttons for first items and add the items to the other
      for (let index = 0; index < element.childNodes.length; index++) {
        const child = element.childNodes[index];
        const rb = getArrayRemoveButton(child);
        if (rb === null || rb.nodeType !== Node.ELEMENT_NODE) {
          return;
        }
        if (index === 0) disableArrayRemoveButton(rb as Element);
        else enableArrayRemoveButton(rb as Element);
      }
    });

    const allButtons = document.querySelectorAll(".array-item-toolbox > .btn-group > .btn");
    Array.prototype.filter.call(allButtons, (element: HTMLElement) => {
      // Remove "fjern" buttons for first items and add the items to the other
      element.setAttribute("tabindex", "0");
    });
  }, []);

  const checkForAndDisableSuppressedFields = React.useCallback((currentFormData: any, currentUiSchema: UiSchema): void => {
    IterateSuppressibleFields(
      currentFormData,
      currentUiSchema,
      (fieldGroupUiSchemaName, fieldId, _suppressFieldId, fieldValue, suppressValue, fieldUiSchema, suppressingFieldUiSchema, repeatable, itemDataIndex) => {
        const fieldElement = repeatable
          ? document.getElementById(`root_${fieldGroupUiSchemaName}_${itemDataIndex}_${fieldId}`)
          : document.getElementById(`root_${fieldGroupUiSchemaName}_${fieldId}`);
        const suppressFieldElement = repeatable
          ? document.getElementById(`root_${fieldGroupUiSchemaName}_${itemDataIndex}_${fieldUiSchema.canbesuppressedbyfieldid}`)
          : document.getElementById(`root_${fieldGroupUiSchemaName}_${fieldUiSchema.canbesuppressedbyfieldid}`);

        if (fieldValue && fieldValue.length > 0) {
          suppressFieldElement?.setAttribute("disabled", "true");
        } else {
          if (suppressingFieldUiSchema["ui:disabled"] !== true) suppressFieldElement?.removeAttribute("disabled");
        }
        if (suppressValue) {
          fieldElement?.setAttribute("disabled", "true");
        } else {
          if (fieldUiSchema["ui:disabled"] !== true) fieldElement?.removeAttribute("disabled");
        }
      }
    );

    IterateSuppressibleMandatoryFields(
      currentFormData,
      currentUiSchema,
      (fieldGroupUiSchemaName, fieldId, _suppressFieldId, fieldValue, suppressValue, fieldUiSchema, suppressingFieldUiSchema, repeatable, itemDataIndex) => {
        const fieldElement = repeatable
          ? document.getElementById(`root_${fieldGroupUiSchemaName}_${itemDataIndex}_${fieldId}`)
          : document.getElementById(`root_${fieldGroupUiSchemaName}_${fieldId}`);
        const suppressFieldElement = repeatable
          ? document.getElementById(`root_${fieldGroupUiSchemaName}_${itemDataIndex}_${fieldUiSchema.ismandatorycanbesuppressedbyfieldid}`)
          : document.getElementById(`root_${fieldGroupUiSchemaName}_${fieldUiSchema.ismandatorycanbesuppressedbyfieldid}`);

        if (fieldValue && fieldValue.length > 0) {
          suppressFieldElement?.setAttribute("disabled", "true");
        } else {
          if (suppressingFieldUiSchema["ui:disabled"] !== true) suppressFieldElement?.removeAttribute("disabled");
        }
        if (suppressValue) {
          fieldElement?.setAttribute("disabled", "true");
        } else {
          if (fieldUiSchema["ui:disabled"] !== true) fieldElement?.removeAttribute("disabled");
        }
      }
    );
  }, []);

  React.useEffect(() => {
    checkForAndDisableSuppressedFields(formData, uiSchema);
    checkForAndFixArrayButtons();
  }, [jsonSchema, formData, uiSchema, checkForAndFixArrayButtons, checkForAndDisableSuppressedFields]);

  const onValidate = (formdata: object, errors: FormValidation) => {
    if (!validate) {
      return true;
    }

    validateMandatorySupressibleFields(formdata, errors);
    validateSupressibleFields(formdata, errors);

    return validate(formdata, errors, uiSchema);
  };

  const onFormDataChange = (e: IChangeEvent) => {
    setCurrentChangeEvent(e);

    checkForAndDisableSuppressedFields(e.formData, e.uiSchema);
    checkForAndFixArrayButtons();

    onDataChanged(e);
    setHasErrors(e.errors.length > 0);
  };

  const setGlyphIconsText = (icon: string, text: string) => {
    Array.prototype.filter.call(document.getElementsByClassName(icon), (element: HTMLElement) => {
      element.innerText = text;
    });
  };

  const getArrayRemoveButton = (node: Node): Node | null => node.childNodes[1].childNodes[0].lastChild;

  const disableArrayRemoveButton = (element: Element): void => {
    if (element.classList.contains("d-none")) {
      return;
    }
    element.classList.add("d-none");
  };

  const enableArrayRemoveButton = (element: Element): void => {
    if (element.classList.contains("d-none") === null) {
      return;
    }
    element.classList.remove("d-none");
  };

  const validateMandatorySupressibleFields = (currentFormData: object, errors: FormValidation): void => {
    IterateSuppressibleMandatoryFields(
      currentFormData,
      uiSchema,
      (fieldGroupUiSchemaName, fieldId, suppressFieldId, fieldValue, suppressValue, _fieldUiSchema, _suppressingFieldUiSchema, repeatable, itemDataIndex) => {
        if (fieldValue && suppressValue) {
          const schemaItem = repeatable ? jsonSchema.properties[fieldGroupUiSchemaName].items : jsonSchema.properties[fieldGroupUiSchemaName];
          const errorItem = repeatable ? errors[fieldGroupUiSchemaName][itemDataIndex][suppressFieldId] : errors[fieldGroupUiSchemaName][suppressFieldId];

          errorItem.addError(
            "Felterne '" + schemaItem.properties[fieldId].title + "' og '" + schemaItem.properties[suppressFieldId].title + "' kan ikke udfyldes samtidig"
          );
        }
      }
    );
  };

  const validateSupressibleFields = (currentFormData: object, errors: FormValidation): void => {
    IterateSuppressibleFields(
      currentFormData,
      uiSchema,
      (fieldGroupUiSchemaName, fieldId, suppressFieldId, fieldValue, suppressValue, _fieldUiSchema, _suppressingFieldUiSchema, repeatable, itemDataIndex) => {
        if (fieldValue && suppressValue) {
          const schemaItem = repeatable ? jsonSchema.properties[fieldGroupUiSchemaName].items : jsonSchema.properties[fieldGroupUiSchemaName];
          const errorItem = repeatable ? errors[fieldGroupUiSchemaName][itemDataIndex][suppressFieldId] : errors[fieldGroupUiSchemaName][suppressFieldId];

          errorItem.addError(
            "Felterne '" + schemaItem.properties[fieldId].title + "' og '" + schemaItem.properties[suppressFieldId].title + "' kan ikke udfyldes samtidig"
          );
        }
      }
    );
  };

  const IterateSuppressibleMandatoryFields = (
    currentFormData: any,
    currentUiSchema: UiSchema,
    callback: (
      fieldGroupUiSchemaName: string,
      fieldId: string,
      suppressFieldId: string,
      fieldValue: string,
      suppressValue: string,
      fieldUiSchema: any,
      suppressingFieldUiSchema: any,
      repeatable: boolean,
      itemDataIndex: string
    ) => void
  ) => {
    for (const fieldGroupUiSchemaName in currentUiSchema) {
      if (!currentUiSchema.hasOwnProperty(fieldGroupUiSchemaName)) {
        continue;
      }

      const fieldGroupUiSchema = currentUiSchema[fieldGroupUiSchemaName];

      const fieldGroupFieldIterator = fieldGroupUiSchema.items ?? fieldGroupUiSchema;

      if (fieldGroupFieldIterator) {
        for (const fieldId in fieldGroupFieldIterator) {
          if (!fieldGroupFieldIterator.hasOwnProperty(fieldId)) {
            continue;
          }

          const fieldUiSchema = fieldGroupFieldIterator[fieldId];
          if (
            !fieldUiSchema.ismandatorycanbesuppressedbyfieldid ||
            !fieldGroupFieldIterator.hasOwnProperty(fieldUiSchema.ismandatorycanbesuppressedbyfieldid)
          ) {
            continue;
          }

          // Suppressible field found - disable counterpart if value exists
          const suppressingFieldUiSchema = fieldGroupFieldIterator[fieldUiSchema.ismandatorycanbesuppressedbyfieldid];
          if (fieldUiSchema && suppressingFieldUiSchema) {
            const repeatable = fieldGroupUiSchema.items ? true : false;
            const itemData = repeatable ? currentFormData[fieldGroupUiSchemaName] : [currentFormData[fieldGroupUiSchemaName]];

            for (const itemDataIndex in itemData) {
              if (!itemData.hasOwnProperty(itemDataIndex)) {
                continue;
              }

              const suppressValue = itemData && itemData[itemDataIndex] && itemData[itemDataIndex][fieldUiSchema.ismandatorycanbesuppressedbyfieldid];
              const fieldValue = itemData && itemData[itemDataIndex] && itemData[itemDataIndex][fieldId];

              callback(
                fieldGroupUiSchemaName,
                fieldId,
                fieldUiSchema.ismandatorycanbesuppressedbyfieldid,
                fieldValue,
                suppressValue,
                fieldUiSchema,
                suppressingFieldUiSchema,
                repeatable,
                itemDataIndex
              );
            }
          }
        }
      }
    }
  };

  const IterateSuppressibleFields = (
    currentFormData: any,
    currentUiSchema: UiSchema,
    callback: (
      fieldGroupUiSchemaName: string,
      fieldId: string,
      suppressFieldId: string,
      fieldValue: string,
      suppressValue: string,
      fieldUiSchema: any,
      suppressingFieldUiSchema: any,
      repeatable: boolean,
      itemDataIndex: string
    ) => void
  ) => {
    for (const fieldGroupUiSchemaName in currentUiSchema) {
      if (!currentUiSchema.hasOwnProperty(fieldGroupUiSchemaName)) {
        continue;
      }

      const fieldGroupUiSchema = currentUiSchema[fieldGroupUiSchemaName];

      const fieldGroupFieldIterator = fieldGroupUiSchema.items ?? fieldGroupUiSchema;

      if (fieldGroupFieldIterator) {
        for (const fieldId in fieldGroupFieldIterator) {
          if (!fieldGroupFieldIterator.hasOwnProperty(fieldId)) {
            continue;
          }

          const fieldUiSchema = fieldGroupFieldIterator[fieldId];
          if (!fieldUiSchema.canbesuppressedbyfieldid || !fieldGroupFieldIterator.hasOwnProperty(fieldUiSchema.canbesuppressedbyfieldid)) {
            continue;
          }

          // Suppressible field found - disable counterpart if value exists
          const suppressingFieldUiSchema = fieldGroupFieldIterator[fieldUiSchema.canbesuppressedbyfieldid];
          if (fieldUiSchema && suppressingFieldUiSchema) {
            const repeatable = fieldGroupUiSchema.items ? true : false;
            const itemData = repeatable ? currentFormData[fieldGroupUiSchemaName] : [currentFormData[fieldGroupUiSchemaName]];

            for (const itemDataIndex in itemData) {
              if (!itemData.hasOwnProperty(itemDataIndex)) {
                continue;
              }

              const suppressValue = itemData && itemData[itemDataIndex] && itemData[itemDataIndex][fieldUiSchema.canbesuppressedbyfieldid];
              const fieldValue = itemData && itemData[itemDataIndex] && itemData[itemDataIndex][fieldId];

              callback(
                fieldGroupUiSchemaName,
                fieldId,
                fieldUiSchema.canbesuppressedbyfieldid,
                fieldValue,
                suppressValue,
                fieldUiSchema,
                suppressingFieldUiSchema,
                repeatable,
                itemDataIndex
              );
            }
          }
        }
      }
    }
  };

  return (
    <div className="row">
      <div className={"message-info clearfix" + (noOffset === true ? " col" : " col-lg-8 offset-lg-2")}>
        {headerRenderFunc && headerRenderFunc()}
        <Form
          disabled={disabled}
          FieldTemplate={CustomFieldTemplate}
          //  ArrayFieldTemplate={CustomArrayFieldTemplate}
          className="message-info-form"
          onSubmit={onSubmit}
          validate={onValidate}
          liveValidate={hasErrors}
          noHtml5Validate={true}
          safeRenderCompletion={true}
          showErrorList={false}
          schema={jsonSchema}
          uiSchema={uiSchema}
          formData={formData}
          onChange={onFormDataChange}
          formContext={{ onNotifyAutoCompleteFields: (e: IChangeEvent) => onFormDataChange(e), changeEvent: currentChangeEvent }}
          onError={errors => setHasErrors(errors.length > 0)}
          transformErrors={errors => TransformErrors(jsonSchema, uiSchema, errors)}
          widgets={{
            fileWithThumbnailWidget: FileWithThumbnailWidget,
            customDateWidget: CustomDateWidget,
            customPublicationDateWidget: CustomDateWidget,
            readonlyTextWidget: ReadonlyTextWidget,
            cvrWidget: CvrWidget,
            decimalNumberWidget: DecimalNumberWidget,
            timeWidget: CustomTimeWidget,
            streetNameWidget: StreetNameWidget,
            eanNumberWidget: EanWidget,
          }}
        >
          {hasErrors && <div className="alert alert-danger">Et eller flere felter ovenfor er udfyldt forkert eller mangler udfyldelse.</div>}
          {buttonsRenderFunc && buttonsRenderFunc(isFormDirty)}
        </Form>
      </div>
    </div>
  );
};

function mapStateToProps(state: any, ownProps: any) {
  return { state, ...ownProps };
}

export default connect(mapStateToProps, dispatch => bindActionCreators({}, dispatch))(MessageInfo);
