import { createAction } from "../actionHelper";
import { apiGET } from "../../api";
import axios from "axios";
import {
  IProductionCalendarOverviewModel,
  IProductionCalendarDay,
  IProductionCalendarData,
  IProductionCalendarDayMessages,
  IProductionLog,
  IProductionCalendarDayState,
  PublicationCalendarDateRangeEnum,
  IFrontPageDeadlineModel,
} from "./types";
import { Dispatch } from "redux";

const LOADING_OVERVIEW = "LOADING_OVERVIEW";
const RECEIVE_OVERVIEW = "RECEIVE_OVERVIEW";
const RECEIVE_INITIAL = "RECEIVE_INITIAL";
const RECEIVE_PRODUCTION_LOG = "RECEIVE_PRODUCTION_LOG";
const SET_DAYS = "SET_DAYS";
const SET_SELECTED_DAY = "SET_SELECTED_DAY";
const SET_SELECTED_DATE_RANGE = "SET_SELECTED_DATE_RANGE";
const GET_FRONTPAGE_DEADLINES = "GET_FRONTPAGE_DEADLINES";
export const SET_PUBLICATION_ACTION_STARTED = "SET_PUBLICATION_ACTION_STARTED";

export interface IProductionCalendarState {
  messages?: IProductionCalendarDayMessages;
  canStartPreparePublication: boolean;
  oldestPublicationDate?: Date;
  nextPublicationDay?: Date;
  publicationStates?: IProductionCalendarDayState[];
  productionLog?: IProductionLog[];
  days?: IProductionCalendarDay[];
  selectedMonth?: number;
  selectedYear?: number;
  selectedDay?: Date;
  selectedDateRange?: PublicationCalendarDateRangeEnum;
  preparationStarted: boolean;
  overviewLoading: boolean;
  frontpageDeadlines?: IFrontPageDeadlineModel;
}

interface ISetDaysModel {
  days: IProductionCalendarDay[];
  selectedYear?: number;
  selectedMonth?: number;
}

const BASE_URL = "api/ProductionCalendar";

// Actions
const isLoadingOveview = (loading: boolean) => createAction(LOADING_OVERVIEW, loading);
const receiveOverview = (overview: IProductionCalendarOverviewModel) => createAction(RECEIVE_OVERVIEW, overview);
const receiveInitial = (initial: IProductionCalendarData) => createAction(RECEIVE_INITIAL, initial);
const receiveProductionLog = (log: IProductionLog[]) => createAction(RECEIVE_PRODUCTION_LOG, log);
const setDay = (day: Date) => createAction(SET_SELECTED_DAY, day);
const setDays = (daysModel: ISetDaysModel) => createAction(SET_DAYS, daysModel);
const setDateRange = (range: PublicationCalendarDateRangeEnum) => createAction(SET_SELECTED_DATE_RANGE, range);
export const setPublicationActionStarted = (isStarted: boolean) => createAction(SET_PUBLICATION_ACTION_STARTED, isStarted);
const receiveDeadlines = (productionCalendarData: IProductionCalendarData) => createAction(GET_FRONTPAGE_DEADLINES, productionCalendarData);

const fetchOverviewData = async (dispatch: Dispatch, year: number, month: number, day: number, dateRange: PublicationCalendarDateRangeEnum) => {
  dispatch(isLoadingOveview(true));
  const overview = await apiGET<IProductionCalendarOverviewModel>(BASE_URL + "/overview", { params: { year, month, day, dateRange } });
  dispatch(receiveOverview(overview));
  dispatch(isLoadingOveview(false));
  getProductionLog(dispatch, new Date(year, month - 1, day));
};

const preparePublication = () => async (dispatch: Dispatch, getState: any) => {
  await publicationAction(dispatch, getState, "prepare");
};

const reopenPublication = () => async (dispatch: Dispatch, getState: any) => {
  await publicationAction(dispatch, getState, "reopen");
};

const getFrontpageDeadlines = () => async (dispatch: any, getState: any) => {
  const productionCalendar = getState().productionCalendar;

  if (productionCalendar.frontpageDeadlines) {
    return;
  }
  dispatch(receiveDeadlines(await apiGET<IProductionCalendarData>(`${BASE_URL}/deadlines/frontpage`)));
};

const publicationAction = async (dispatch: Dispatch, getState: any, method: string) => {
  if (method === "prepare") {
    dispatch(setPublicationActionStarted(true));
  }

  const { selectedYear: year, selectedMonth: month, selectedDay: date, selectedDateRange: dateRange } = getState().productionCalendar;
  const day = new Date(date).getDate();
  await axios.get<IProductionCalendarDayState>(`api/ProductionCalendar/publication/${method}`, { params: { year, month, day } });
  await fetchOverviewData(dispatch, year, month, day, dateRange);
  await fetchAndSetDays(dispatch, year, month);

  if (method === "prepare") {
    dispatch(setPublicationActionStarted(false));
  }
};

const initialize = () => async (dispatch: any, getState: any) => {
  let dataDate: Date;
  if (getState().productionCalendar.days !== undefined) {
    dataDate = getState().productionCalendar.selectedDay;
  } else {
    const data = await apiGET<IProductionCalendarData>(BASE_URL);
    const { nextPublicationDay: pd }: IProductionCalendarData = data;
    dispatch(receiveInitial(data));
    dataDate = pd;
  }

  const { year, month, day } = getPartsFromDate(new Date(dataDate));
  await fetchOverviewData(dispatch, year, month, day, PublicationCalendarDateRangeEnum.Day);
  await fetchAndSetDays(dispatch, year, month);
};

const getPartsFromDate = (d: Date) => ({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() });

const setSelectedDay = (day: Date) => async (dispatch: Dispatch, getState: any) => {
  const pc: IProductionCalendarState = getState().productionCalendar;
  dispatch(setDay(day));
  dispatch(setPublicationActionStarted(false));
  await fetchOverviewData(dispatch, pc.selectedYear!, pc.selectedMonth!, day.getDate(), PublicationCalendarDateRangeEnum.Day);
};

const setSelectedMonth = (month: number) => async (dispatch: Dispatch, getState: any) => {
  await fetchAndSetDays(dispatch, getState().productionCalendar.selectedYear, month);
};

const setSelectedDateRange = (range: PublicationCalendarDateRangeEnum) => async (dispatch: Dispatch, getState: any) => {
  const pc: IProductionCalendarState = getState().productionCalendar;
  dispatch(setDateRange(range));
  await fetchOverviewData(dispatch, pc.selectedYear!, pc.selectedMonth!, pc.selectedDay!.getDate(), range);
};

const setSelectedYear = (year: number) => async (dispatch: Dispatch, getState: any) => {
  // make sure we never select a date older than recorded
  const { selectedMonth, oldestPublicationDate } = getState().productionCalendar;
  const d = new Date(oldestPublicationDate);
  let month = selectedMonth;
  const minMonth = d.getMonth() + 1;
  if (d.getFullYear() === year && selectedMonth < minMonth) {
    month = minMonth;
  }

  await fetchAndSetDays(dispatch, year, month);
};

const fetchAndSetDays = async (dispatch: Dispatch, year: number, month: number) => {
  const daysData: any = await fetchDays(year, month);
  dispatch(setDays({ days: daysData.days, selectedYear: year, selectedMonth: month }));
};

const fetchDays = (year?: number, month?: number) => apiGET(`api/ProductionCalendar/${year}/${month}`);

const getProductionLog = async (dispatch: Dispatch, date: Date) => {
  const data = await apiGET<IProductionLog[]>(`api/ProductionLog/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`);
  dispatch(receiveProductionLog(data));
};

export interface IProductionCalendarActions {
  initialize(): void;
  setSelectedMonth(month: number): void;
  setSelectedYear(year: number): void;
  setSelectedDay(day: Date): void;
  setSelectedDateRange(range: PublicationCalendarDateRangeEnum): void;
  preparePublication(): void;
  reopenPublication(): void;
  getFrontpageDeadlines(): void;
}

export const ProductionCalendarActions: IProductionCalendarActions = {
  initialize,
  setSelectedMonth,
  setSelectedYear,
  setSelectedDay,
  setSelectedDateRange,
  preparePublication,
  reopenPublication,
  getFrontpageDeadlines,
};

const initialState: IProductionCalendarState = {
  canStartPreparePublication: false,
  preparationStarted: false,
  overviewLoading: false,
};

export default (state = initialState, action: any): IProductionCalendarState => {
  switch (action.type) {
    case LOADING_OVERVIEW: {
      return { ...state, ...{ overviewLoading: action.payload } };
    }
    case RECEIVE_OVERVIEW: {
      const { messages, canStartPreparePublication, states } = action.payload;

      return {
        ...state,
        ...{ messages, canStartPreparePublication, publicationStates: states },
      };
    }
    case RECEIVE_PRODUCTION_LOG: {
      return { ...state, ...{ productionLog: action.payload } };
    }
    case SET_SELECTED_DAY: {
      return { ...state, ...{ selectedDay: action.payload, selectedDateRange: PublicationCalendarDateRangeEnum.Day } };
    }
    case GET_FRONTPAGE_DEADLINES: {
      return { ...state, ...{ frontpageDeadlines: action.payload } };
    }
    case SET_SELECTED_DATE_RANGE: {
      return { ...state, ...{ selectedDateRange: action.payload } };
    }
    case SET_PUBLICATION_ACTION_STARTED: {
      return { ...state, ...{ preparationStarted: action.payload } };
    }
    case SET_DAYS: {
      return { ...state, ...action.payload };
    }
    case RECEIVE_INITIAL: {
      const { days, oldestPublicationDate, nextPublicationDay }: IProductionCalendarData = action.payload;
      const d = new Date(nextPublicationDay);
      return {
        ...state,
        ...{
          days,
          oldestPublicationDate,
          nextPublicationDay,
          selectedDay: d,
          selectedMonth: d.getMonth() + 1,
          selectedYear: d.getFullYear(),
          selectedDateRange: PublicationCalendarDateRangeEnum.Day,
        },
      };
    }
    default:
      return state;
  }
};
