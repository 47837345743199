import { createAction } from "../actionHelper";
import { IPublicationPattern } from "./IEditMessageState";

export enum EditMessageActionsTypes {
  CLEAR_FIELD_SCHEMA = "CLEAR_FIELD_SCHEMA",
  RECEIVE_EDIT_MESSAGE_FIELDS = "RECEIVE_EDIT_MESSAGE_FIELDS",
  REQUEST_SAVE_MESSAGE = "REQUEST_SAVE_MESSAGE",
  REQUEST_EDIT_MESSAGE = "REQUEST_EDIT_MESSAGE",
  SET_FIELDS_DATA = "SET_FIELDS_DATA",
  SET_DEFAULT_FIELDS_DATA = "SET_DEFAULT_FIELDS_DATA",
  RECEIVE_EDIT_MESSAGE = "RECEIVE_EDIT_MESSAGE",
  SAVED_EDIT_MESSAGE = "SAVED_EDIT_MESSAGE",
  REQUEST_SET_MESSAGE_TYPE = "REQUEST_SET_MESSAGE_TYPE",
  RECEIVE_SET_MESSAGE_TYPE = "RECEIVE_SET_MESSAGE_TYPE",
  START_NEW = "START_NEW",
  CLEAR_FLOW = "CLEAR_FLOW",
  SET_PUBLICATiON_PATTERNS = "SET_PUBLICATION_PATTERNS",
}

export enum ResultStateCode {
  Ok = 10,
  BadRequest = 20,
  NotFound = 30,
  Forbidden = 40,
  ErrorInMessageStructure = 50,
  ErrorOldConcurrencyToken = 60,
  ErrorInCorrect = 70,
  BusinessError = 80,
}

const Actions = EditMessageActionsTypes;

const setMessageType = (messageTypeId: number) => createAction(Actions.REQUEST_SET_MESSAGE_TYPE, messageTypeId);

export const clearEditMessage = () => createAction(Actions.CLEAR_FLOW);

export const setPublicationPatterns = (patterns: IPublicationPattern[]) => createAction(Actions.SET_PUBLICATiON_PATTERNS, patterns);

const saveMessage = (fieldsData?: object, defaultFieldsData?: object) => createAction(Actions.REQUEST_SAVE_MESSAGE, { fieldsData, defaultFieldsData });

const setFieldsData = (fieldsData: object) => createAction(Actions.SET_FIELDS_DATA, { fieldsData });

const setDefaultFieldsData = (defaultFieldsData: object) => createAction(Actions.SET_DEFAULT_FIELDS_DATA, { defaultFieldsData });

const getEditMessage = (messageNumber: string) => createAction(Actions.REQUEST_EDIT_MESSAGE, messageNumber);

const startNew = () => createAction(Actions.START_NEW);

export interface IEditMessageActions {
  startNew(): void;
  getEditMessage(messageNumber: string): void;
  saveMessage(fieldsData?: object, defaultFieldsData?: object): void;
  setMessageType(messageTypeId: number): void;
  setFieldsData(fieldsData: object): void;
  setDefaultFieldsData(defaultFieldsData: object): void;
  clearEditMessage(): void;
}

export const EditMessageActions: IEditMessageActions = {
  getEditMessage,
  saveMessage,
  clearEditMessage,
  setFieldsData,
  setDefaultFieldsData,
  setMessageType,
  startNew,
};
