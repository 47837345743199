import Storage from "./../reducers/user/Storage";

export enum StatstidendePrivileges {
  EditProfile = "editprofile",
  DeleteProfile = "deleteprofile",

  AddMessage = "addmessage",
  CopyMessage = "copymessage",
  EditMessages = "editmessages",
  SetMessageState = "setmessagestate",
  AccessProductionCalendar = "accessproductioncalendar",
  PreparePublication = "preparepublication",
  ReturnMessageToOwner = "returnmessagetoowner",
  UndoReturnMessageToOwner = "undoreturnmessagetoowner",
  OverruleDeadline = "overruledeadline",
  SetMessageFee = "setmessagefee",
  ViewMessageOwnerProfile = "viewmessageownerprofile",
  ViewMessageOwnerProfileDetails = "viewmessageownerprofiledetails",

  AdminNews = "adminnews",
  AdminSiteContent = "adminsitecontent",
  AdminMessageLog = "adminmessagelog",
  AdminMessageMeta = "adminmessagemeta",
  AdminDefaultTexts = "editdefaulttext",
  AdminEditMessageType = "admineditmessagetype",
  AdminUsers = "adminusers",
  AdminServiceClients = "adminserviceclients",
  AdminMessageType = "adminmessagetype",
  AdminJobSummary = "adminjobsummary",
  GlobalAdminTeams = "globaladminteams",
  AccessInvoiceFiles = "accessinvoicefiles",
  CanCreateCorrectionMessage = "cancreatecorrectionmessage",
  CanCreateReprintMessages = "cancreatereprintmessages",
  CanToggleCorrectionType = "cantogglecorrectiontype",
  CanCorrectAndSumbit = "cancorrectandsubmit",
  CanHideMessageFromSearch = "canhidemessagefromsearches",
  CanViewReprintedOriginals = "canviewreprintedoriginals",
  LookupCprNumbersInMessages = "lookupcprnumbersinmessages",
  LookupCvrNumbersInMessages = "lookupcvrnumbersinmessages",
  AdminTeams = "adminteams",
  AdminOwnTeams = "adminownteams",
  ViewUserMessageLog = "viewusermessagelog",
  IsTeamMember = "isteammember",
  CanPreviewMessage = "canpreviewmessage",
  CanReceiveMissingPublicationPreparationNotifications = "canreceivemissingpublicationpreparationnotifications",
}

const getToken = (): any => {
  const token = Storage.GetValidToken();
  if (token === undefined) {
    return [""];
  }

  return token;
};

export const GetUserId = (): number => parseInt(getToken().dkstatstidendeattributeUserProfileId, 10);
export const GetClaimPrivileges = (): string[] => getToken().dkstatstidendeattributePrivileges;
export const GetClaimPrivilegeMissingAsAssuranceLevelToLow = (): string => getToken().dkstatstidendeattributeIsPrivilegeMissingAsAssuranceLevelToLow;
const GetCvrNumber = (): string => getToken().dkstatstidendecvr;

export const HasClaimPrivilege = (privilege: string): boolean => {
  const privileges = GetClaimPrivileges();
  if (privileges === undefined) {
    return false;
  }
  return privileges.map(x => x.split(";")[0]).indexOf(privilege) > -1;
};

export const IsTeamMember = (): boolean => {
  return HasClaimPrivilege(StatstidendePrivileges.IsTeamMember);
};

export const HasSameCvr = (cvr: string): boolean => {
  const tokenCvr = GetCvrNumber();
  return cvr === tokenCvr;
};
