import { call, fork, put } from "redux-saga/effects";
import { createAction } from "../actionHelper";
import { apiGET } from "../../api";
import { ISectionModel } from "./sectionModels";
import { IMessageTypeModel } from "./";
import { setPublicationPatterns } from "../editMessage/EditMessageActions";
import { IPublicationPattern } from "../editMessage/IEditMessageState";

// ACTIONS
const REQUEST_SECTIONS = "REQUEST_SECTIONS";
const RECEIVE_SECTIONS = "RECEIVE_SECTIONS";

// API
const GETSections = () => apiGET<ISectionModel[]>("api/section");

export interface ISectionsState {
  sections?: ISectionModel[];
}

const receiveSections = (sections: ISectionModel[]) => createAction(RECEIVE_SECTIONS, sections);

const updateSections = () => async (dispatch: any) => {
  const sections = await GETSections();
  dispatch(receiveSections(sections));
};

// Actions Interface
export interface ISectionsActions {
  updateSections: () => void;
}

export const SectionActions: ISectionsActions = {
  updateSections,
};

// SECTION SAGAS
function* initSections() {
  const sections: ISectionModel[] = yield call(GETSections);
  const publicationPatterns: IPublicationPattern[] = (sections as any).flatMap((x: any) =>
    x.messageTypes.flatMap(({ id, publicationPatternId }: IMessageTypeModel) => ({ id, publicationPatternId }))
  );
  yield put(setPublicationPatterns(publicationPatterns));
  yield put(receiveSections(sections));
}

export const sectionSagas = [fork(initSections)];

export default (state: ISectionsState = {}, action: any): ISectionsState => {
  switch (action.type) {
    case RECEIVE_SECTIONS:
      return { ...state, ...{ sections: action.payload } };
    default:
      return state;
  }
};
