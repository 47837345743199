import "./CompanyInfo.scss";
import React, { useEffect } from "react";
import { ICompanyResult } from "../../../containers/message/models/ICompanyResult";
import { Button, Card, CardText, CardTitle } from "reactstrap";
import { Spinner } from "@lexdania/components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CvrActions, ICvrActions, ICvrReducerState } from "../../../reducers/cvr/cvrReducer";

export interface CompanyInfoProps extends ICvrActions {
  value: string | undefined;
  eanNumberMode: boolean;
  hasAutoCompleteFields: boolean;
  onAutoCompleteFieldsClick?: (companyResult: ICompanyResult) => void;
  companies: ICvrReducerState;
}

const CompanyInfo: React.FC<CompanyInfoProps> = ({
  hasAutoCompleteFields,
  onAutoCompleteFieldsClick: onCvrAutoCompleteClick,
  value,
  eanNumberMode: isEanNumberMode,
  getCvr,
  getEan,
  companies,
}: CompanyInfoProps) => {
  const { eanCompanyMap, cvrCompanyMap } = companies;

  const isCvr = (cvr: string) => {
    return cvr.length === 8 && /^\d+$/.test(cvr);
  };
  const isEanNumber = (ean: string) => {
    return ean.length === 13 && /^\d+$/.test(ean);
  };

  useEffect(() => {
    if (value) {
      if (isEanNumberMode && isEanNumber(value)) {
        getEan(value);
      } else if (!isEanNumberMode && isCvr(value)) {
        getCvr(value);
      }
    }
  }, [value, isEanNumberMode, getCvr, getEan]);

  const showError = (statusCode: number) => {
    if (!isEanNumberMode)
      switch (statusCode) {
        case 404:
          return <CardTitle>CVR-oplysninger ej fundet.</CardTitle>;
        case 502:
          return (
            <CardTitle>Der kan ikke hentes data fra CVR i øjeblikket. Du kan fortsætte, men tjek gerne, at de indtastede oplysninger er korrekte.</CardTitle>
          );
        default:
          return <CardTitle>Ukendt fejl, kontakt os.</CardTitle>;
      }
    else
      switch (statusCode) {
        case 404:
          return <CardTitle>EAN og CVR-oplysninger ej fundet.</CardTitle>;
        case 502:
          return (
            <CardTitle>
              Der kan ikke hentes data vedrørende EAN-nummer i øjeblikket. Du kan fortsætte, men tjek gerne, at de indtastede oplysninger er korrekte.
            </CardTitle>
          );
        default:
          return <CardTitle>Ukendt fejl, kontakt os.</CardTitle>;
      }
  };

  if (!value) return <></>;

  const itemFromCache = isEanNumberMode ? eanCompanyMap.get(value) : cvrCompanyMap.get(value);
  const companyResult = itemFromCache?.company;
  const isLoading = itemFromCache?.isLoading;

  return (
    <Spinner isLoading={isLoading ?? false}>
      {isLoading && (
        <Card body={true} className="ean-data mt-3">
          <CardTitle>{isEanNumberMode ? "Henter EAN og CVR-oplysninger..." : "Henter CVR data..."}</CardTitle>
        </Card>
      )}
      {companyResult ? (
        <Card body={true} className="company-data mt-3">
          {companyResult.statusCode === 200 && companyResult.company !== undefined ? (
            <React.Fragment>
              <CardTitle style={{ display: "flex", justifyContent: "space-between" }}>
                {isEanNumberMode ? <>Fundne EAN og CVR-oplysninger på baggrund af EAN-nummer:</> : <>Fundne CVR-oplysninger:</>}
                {hasAutoCompleteFields && (
                  <Button
                    type="submit"
                    color="primary"
                    onClick={e => {
                      e.preventDefault();
                      if (onCvrAutoCompleteClick) onCvrAutoCompleteClick(companyResult);
                    }}
                  >
                    Udfyld formular
                  </Button>
                )}
              </CardTitle>
              <CardText className="d-md-flex justify-content-md-between">
                <span>
                  {companyResult.company.unitName && (
                    <>
                      Enhedsnavn: {companyResult.company.unitName}
                      <br />
                    </>
                  )}
                  {companyResult.company.name}
                  <br />
                  {companyResult.company.address && (
                    <>
                      {companyResult.company.address} <br />
                    </>
                  )}
                  {companyResult.company.address && (
                    <>
                      {companyResult.company.zip} {companyResult.company.city}
                      <br />
                    </>
                  )}
                  {companyResult.company.cvr && <>CVR: {companyResult.company.cvr}</>}

                  {!isEanNumberMode && (
                    <>
                      <br />
                      {companyResult.company.previousName && <>Forrige navn: {companyResult.company.previousName}</>}
                    </>
                  )}
                </span>
              </CardText>
            </React.Fragment>
          ) : (
            showError(companyResult.statusCode)
          )}
        </Card>
      ) : (
        <></>
      )}
    </Spinner>
  );
};

export default connect(
  ({ companies }: any) => ({ companies }),
  dispatch => bindActionCreators({ ...CvrActions }, dispatch)
)(CompanyInfo);
