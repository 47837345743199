import * as React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
const qs = require("query-string");
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UserActions, IUserState, IUserActions } from "../../reducers/user";

interface ILoginHandlerState {
  returnUrl?: string;
  query: any;
}

interface ILoginHandlerProps extends IUserActions, RouteComponentProps<{}> {
  user: IUserState;
}

class LoginHandler extends React.Component<ILoginHandlerProps, ILoginHandlerState> {
  public state: ILoginHandlerState = {
    query: {},
  };

  public componentDidMount() {
    const { location } = this.props;
    const query = qs.parse(location.search);

    this.setState({ query });
  }

  public render() {
    const { user } = this.props;
    const { query } = this.state;

    if (user.isDisabled) {
      return <Redirect to="/UserDisabled" />;
    }

    if (!user.profile) {
      return <div>Logger dig på...</div>;
    }

    if (!user.isComplete) {
      return <Redirect to="/MyPage/Profile" />;
    }
    if (query.returnUrl && query.returnUrl !== "/error") {
      return <Redirect to={query.returnUrl} push={true} />;
    }

    return <Redirect to="/" push={true} />;
  }
}

export default connect(
  ({ user }: any, ownProps: any) => ({
    ...ownProps,
    user,
  }),
  dispatch => bindActionCreators({ ...UserActions }, dispatch)
)(LoginHandler);
