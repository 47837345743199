import { apiGET, apiPOST } from "./../../api";
import { IMessageState } from "../../components/viewmodels/MessageModels";

// RESPONSE INTERFACES
export interface IMessageTypesResponse {
  jsonSchema: any;
  uiSchema: any;
  sectionName: string;
}

export interface IMessageValidationResult {
  messageNumber: string;
  isValid: boolean;
  errors: string[];
}

export interface IEditMessageModel {
  messageNumber: string;
  messageTypeId: number;
  fieldsData: string;
  defaultFieldsData: string;
  state: IMessageState;
  correctionOfMessageNumber?: string;
  concurrencyToken: Uint32Array[];
}

// API METHODS
const schemaBaseGET = (id: number, schemaType: "fields" | "defaultfields") => apiGET<IMessageTypesResponse>(`api/messagetypeschema/${id}/${schemaType}`);

// Schema
export const GETFieldsSchema = (messageTypeId: number) => schemaBaseGET(messageTypeId, "fields");
export const GETDefaultFieldsSchema = (messageTypeId: number) => schemaBaseGET(messageTypeId, "defaultfields");
export const GETEditMessage = (messageNumber: string) => apiGET<IEditMessageModel>(`api/message/${messageNumber}/formdata`);
export const GETDefaultFormData = (messageTypeId: number) => apiGET<IEditMessageModel>(`api/message/${messageTypeId}/defaultformdata`);
export const POSTSaveMessage = (message: any) => apiPOST<IMessageValidationResult>("api/message", message as any);
