import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import { IStatstidendeJwtToken } from "./types";

const JWT_TOKEN = (window as any).signinCookieAudience + "_id_token";
const JWT_DOMAIN = (window as any).signinCookieDomain;

const removeColon = (o: any): any => {
  const newObject = {} as any;
  Object.keys(o).map(x => (newObject[x.replace(/:/g, "")] = o[x]));
  return newObject;
};

export default class Storage {
  public static JWTGet(): IStatstidendeJwtToken | undefined {
    const token = Cookies.get(JWT_TOKEN);
    if (!token) {
      return undefined;
    }
    return removeColon(jwt_decode<IStatstidendeJwtToken>(token));
  }

  public static JWTGetTokenString(): string | undefined {
    return Cookies.get(JWT_TOKEN);
  }

  public static JWTGetTokenAndTokenString(): [IStatstidendeJwtToken, string] | undefined {
    const tokenString = Cookies.get(JWT_TOKEN);
    if (!tokenString) {
      return undefined;
    }
    return [removeColon(jwt_decode<IStatstidendeJwtToken>(tokenString)), tokenString];
  }

  public static HasValidToken(): boolean {
    const token = Storage.JWTGet();
    if (!token) {
      return false;
    }
    return !Storage.isJWTExpired(token);
  }

  public static GetValidToken(): IStatstidendeJwtToken | undefined {
    const token = Storage.JWTGet();
    if (!token || Storage.isJWTExpired(token)) {
      return undefined;
    }
    return removeColon(token);
  }

  public static JWTRemove(): void {
    return Cookies.remove(JWT_TOKEN);
  }

  public static SetJWT(token: string): void {
    Cookies.set(JWT_TOKEN, token, { domain: JWT_DOMAIN });
  }

  public static isJWTExpired = (token: IStatstidendeJwtToken) => {
    try {
      return token.exp < Date.now() / 1000; // Checking if token is expired. N
    } catch (err) {
      return false;
    }
  };
}
