import "./AddressPicker.scss";
import * as React from "react";
import { InputGroup } from "reactstrap";
import { IAddress } from "../../../containers/message/models/IAddress";

interface IState {
  isPickingAddress: boolean; // Used to flip between address picker component and textfield; textfield shows only street name part of address
  currentValue: string;
  addressPicked: boolean; // Used to flag text field focus whether focus was due to autocomplete or manual focus
}

interface IAddressPickerProps {
  value: any;
  onAddressSelected: (address: IAddress) => void;
  onChange: (value: any) => void;
  id: string;
  pattern?: string;
  maxLength?: number;
  placeholder?: string;
  required?: boolean;
  onAutocompleteChange?: (streetName: string) => void;
  disabled?: boolean;
}

class AddressPicker extends React.Component<IAddressPickerProps, IState> {
  private addressInput: React.RefObject<HTMLInputElement>;
  private addressTextInput: React.RefObject<HTMLInputElement>;
  private dawaAutoComplete: any;

  constructor(props: IAddressPickerProps) {
    super(props);

    this.state = {
      isPickingAddress: false,
      addressPicked: false,
      currentValue: props.value ? props.value : "",
    };

    this.addressInput = React.createRef<HTMLInputElement>();
    this.addressTextInput = React.createRef<HTMLInputElement>();
  }

  public componentDidMount() {
    // Setup autocomplete component
    var dawaAutocomplete2 = require("dawa-autocomplete2");
    this.dawaAutoComplete = dawaAutocomplete2.dawaAutocomplete(this.addressInput.current, {
      select: (selected: any) => {
        if (selected && selected.data) {
          this.onAddressSelected(selected.data as IAddress);
        }
      },
      fuzzy: false,
    });

    // Add eventlistener to detect behind the scenes changes in the text field (from autocomplete fields).
    // To make this work, events needs to be dispached manually like: const event = new Event("change", { bubbles: true }); element.dispatchEvent(event);
    this.addressTextInput.current?.addEventListener("change", this.autoCompleteListener);
  }

  private autoCompleteListener = () => {
    const value = this.addressTextInput.current!.value;
    this.setState({
      currentValue: value,
    });

    if (this.addressTextInput.current && this.props.onAutocompleteChange) this.props.onAutocompleteChange(value);
  };

  public componentWillUnmount() {
    // Tear down autocomplete component
    if (this.dawaAutoComplete) this.dawaAutoComplete.destroy();
    this.addressTextInput.current?.removeEventListener("change", this.autoCompleteListener);
  }

  public render() {
    // unique input names (and autocomplete properties) prevents chrome auto suggest values
    const inputName = Math.random().toString();
    const inputTextName = Math.random().toString();

    return (
      <>
        <InputGroup className="address-picker">
          <div className="autocomplete-container">
            {
              // Displays two inputboxes - but only one visible at the time. The _autocomplete one is the actual address picker
              // This is done to only present the user with the street name after address has been selected
              <input
                id={this.props.id + "_autocomplete"}
                pattern={this.props.pattern}
                maxLength={this.props.maxLength}
                placeholder={this.props.placeholder}
                className="form-control"
                type="search"
                ref={this.addressInput}
                name={inputName}
                autoComplete={inputName + "_false"}
                style={this.state.isPickingAddress ? { display: "block" } : { display: "none" }}
                onBlur={() => {
                  this.setState({ isPickingAddress: false });
                }}
                onChange={e => {
                  this.setState({ currentValue: e.target.value });
                  this.props.onChange(e.target.value === "" ? undefined : e.target.value); // Notify forms on data change written
                }}
                aria-label="Fremsøg adresse"
                required={this.props.required}
                disabled={this.props.disabled}
              />
            }
            {
              <input
                id={this.props.id}
                pattern={this.props.pattern}
                maxLength={this.props.maxLength}
                placeholder={this.props.placeholder}
                className="form-control"
                style={this.state.isPickingAddress ? { display: "none" } : { display: "block" }}
                ref={this.addressTextInput}
                type="text"
                value={this.state.currentValue}
                name={inputTextName}
                autoComplete={inputTextName + "_false"}
                onFocus={() => {
                  if (this.state.addressPicked) {
                    // Focus appeared due to address selected. Reset picker state and move cursor to after content
                    this.setState({ addressPicked: false });
                    this.addressTextInput.current?.setSelectionRange(this.state.currentValue.length, this.state.currentValue.length);
                  } else {
                    // Focus appeared because the field was focused manually. Set state to picking address (forcing the address-picker to be shown),
                    // set the value into the picker and focus it
                    this.setState({ isPickingAddress: true }, () => {
                      if (this.addressInput.current) {
                        this.addressInput.current.value = this.state.currentValue;
                        this.addressInput.current.focus();
                      }
                    });
                  }
                }}
                onChange={e => {
                  // Update value and notify forms on change when cursor is in the text field
                  this.setState({ currentValue: e.target.value });
                  this.props.onChange(e.target.value === "" ? undefined : e.target.value); // Notify forms on data change written
                }}
                aria-label="Adresse"
                required={this.props.required}
                disabled={this.props.disabled}
              />
            }
          </div>
        </InputGroup>
      </>
    );
  }

  private onAddressSelected = (address: IAddress) => {
    // Address picked, set state to enable the text-only-field to display the value,
    // hide the autocomplete-field and notify forms and other form-elements about the value change
    this.setState(
      {
        isPickingAddress: false,
        addressPicked: true,
        currentValue: address.vejnavn,
      },
      () => {
        this.props.onChange(address.vejnavn); // Notify forms on address change selected
        this.addressTextInput.current?.focus(); // Change focus to the text field
        this.props.onAddressSelected(address); // Notify other form elements about the change
      }
    );
  };
}

export default AddressPicker;
